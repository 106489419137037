import axios from "axios";
import { requestOtp, verifyOtp } from "../apis/users";

export const NOT_REQUESTED_OTP = "NOT_REQUESTED_OTP";
export const REQUEST_OTP = "REQUEST_OTP";
export const REQUEST_OTP_FAILED = "REQUEST_OTP_FAILED";
export const REQUEST_OTP_SUCCESS = "REQUEST_OTP_SUCCESS";
export const SENT_OTP = "SENT_OTP";
export const VERIFY_OTP = "VERIFY_OTP";
export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILED = "VERIFY_OTP_FAILED";
export const LOGOUT = "LOGOUT";
export const REQUEST_CREATE_SELLER = "REQUEST_CREATE_SELLER";
export const REQUEST_CREATE_SELLER_SUCCESS = "REQUEST_CREATE_SELLER_SUCCESS";
export const REQUEST_CREATE_SELLER_FAILED = "REQUEST_CREATE_SELLER_FAILED";

// example of a thunk using the redux-thunk middleware
export const logout = () => async (dispatch) => {
  dispatch({ type: "LOGOUT" }); // Cards can still be seen in the localhost
  return;
};

export const requestUserOtp = (value) => async (dispatch) => {
  dispatch({ type: REQUEST_OTP, phoneNumber: value });
  return requestOtp(value)
    .then((result) => {
      console.log(result)
      if (result.data.data.code == "OTP_SENT") {
        dispatch({ type: REQUEST_OTP_SUCCESS });
      } else {
        dispatch({ 
          type: REQUEST_OTP_FAILED, 
          errorMsg: result.data.message 
        });
      }
    })
    .catch((e) => {
      if (e.response.status === 500) {
        dispatch({
          type: REQUEST_OTP_FAILED,
          errorMsg: "Something went wrong",
        });
      }
      setTimeout(() => {
        dispatch({ type: NOT_REQUESTED_OTP });
      }, 5000);
    });
};

export const verifyUserOtp = (phonenumber, otp) => async (dispatch) => {
  dispatch({
    type: VERIFY_OTP_REQUEST,
    loading: true,
  });
  return verifyOtp(phonenumber, otp)
    .then((result) => {
      let replyCode = result.data.code;
      if (replyCode == "VERIFICATION_SUCCESS") {
        dispatch({ type: VERIFY_OTP_SUCCESS, result: result.data });
      } else {
        dispatch({ type: VERIFY_OTP_FAILED });
      }
    })
    .catch((e) => {
      if (e.response.status === 403) {
        dispatch({
          type: VERIFY_OTP_FAILED,
          errorMsg: "Make Sure you enter valid OTP",
        });
      } else {
        dispatch({
          type: VERIFY_OTP_FAILED,
          errorMsg: "Something Went Wrong",
        });
      }

      setTimeout(() => {
        dispatch({ type: NOT_REQUESTED_OTP });
      }, 5000);
    });
};
