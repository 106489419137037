import React from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Button,
  Alert,
} from "react-bootstrap";
import { logout } from "../actions/loginActions";
import { LIMITED_ACCESSS } from '../utils/env';

class EjNavbar extends React.Component {
  
  logout = () => {
    this.props.logout();
  };
  
  render() {
    console.log(LIMITED_ACCESSS.includes(this.props.user.phoneNumber));

    return (
      <Navbar className="ej-nav">
        <Navbar.Brand>
          <Link to="/">Echojoy Internal - Ver. 1.0.7 </Link>
        </Navbar.Brand>
        {this.props.auth === false ? (
          <Nav className="mr-auto">
            <Link to="/login">Login</Link>
          </Nav>
        ) : (
          <Nav className="mr-auto">
            <Link to="/">Logged In As:{this.props.user.phoneNumber}</Link>
            <NavLink
              exact
              className="navbarlink"
              activeClassName="is-active"
              to="/"
            >
              Dashboard
            </NavLink>
            <NavLink
              className="navbarlink"
              activeClassName="is-active"
              to="/get-cards"
            >
              Get Cards
            </NavLink>
            {/* <Link to="/useractivity">User Activity</Link> */}
            {/* <Link to="/resellercardsbalances">Balances</Link> */}
            {
              !LIMITED_ACCESSS.includes(this.props.user.phoneNumber) &&
                <NavLink
                  className="navbarlink"
                  activeClassName="is-active"
                  to="/createresellercards"
                  >  
                Reseller Cards
              </NavLink>
            }
            <NavLink
              className="navbarlink"
              activeClassName="is-active"
              to="/refunds"
            >
              Refunds
            </NavLink>
            <NavLink
              className="navbarlink"
              activeClassName="is-active"
              to="/reports"
            >
              Reports
            </NavLink>
            {
              !LIMITED_ACCESSS.includes(this.props.user.phoneNumber) &&
              <NavLink
                className="navbarlink"
                activeClassName="is-active"
                to="/approveamount"
              >
                Capture Payments
              </NavLink>
            }
            {/* <NavLink
              className="navbarlink"
              activeClassName="is-active"
              to="/alluseractivity"
            >
              All User Activity
            </NavLink> */}
            {
              !LIMITED_ACCESSS.includes(this.props.user.phoneNumber) &&
              <NavLink
                className="navbarlink"
                activeClassName="is-active"
                to="/paymentlinks"
              >
                Payment Links
              </NavLink>
            }
            <NavLink
              className="navbarlink"
              activeClassName="is-active"
              to="/userdetails"
            >
              User Details
            </NavLink>
            <NavLink
              className="navbarlink"
              activeClassName="is-active"
              to="/login"
              onClick={this.logout}
            >
              Logout
            </NavLink>
          </Nav>
        )}
      </Navbar>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.user.isAuthenticated,
    user: state.user,
  };
};

export default connect(mapStateToProps, { logout })(EjNavbar);
