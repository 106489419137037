import React from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Alert,
  Spinner,
  Pagination,
  Card,
} from "react-bootstrap";
import { connect } from "react-redux";
import {
  getWaitingApprovalPayments,
  updateApprovalStatus,
  closeAlert,
} from "../../actions/paymentActions";

class ApproveAmountPage extends React.Component {
  state = {
    inProgress: false,
  };

  componentDidMount() {
    this.props.getWaitingApprovalPayments({
      accessCode: this.props.user.accessCode,
    });
  }

  approvePayment = async (data) => {
    await this.setState({
      inProgress: true,
    });
    await this.props.updateApprovalStatus({
      paymentId: data.paymentId,
      approvalStatus: "APPROVAL_SUCCEEDED",
      phonenumber: data.phonenumber,
      transactionType: data.paymentMethod.transactionType,
      amount: data.paymentMethod.razorpayPaymentAmount,
      cardId: data.paymentMethod.cardId,
      productId: data.paymentMethod.productId,
      transactionId: data.paymentMethod.transactionId,
      accessCode: this.props.user.accessCode,
    });
    await this.setState({
      inProgress: false,
    });
  };

  closeAlert = () => {
    this.props.closeAlert();
  };

  render() {
    return (
      <Container>
        <Row>
          <Col sm={{ span: 8, offset: 2 }} style={{ marginTop: 30 }}>
            <h5 className="element-header">Payment Approval</h5>
            {this.props.paymentApprovals.approval_status === true &&
            this.props.paymentApprovals.variant === "success" ? (
              <Alert variant="success" onClose={this.closeAlert} dismissible>
                Successfully captured payment.
              </Alert>
            ) : null}

            {this.props.paymentApprovals.approval_status === true &&
            this.props.paymentApprovals.variant === "danger" ? (
              <Alert variant="danger" onClose={this.closeAlert} dismissible>
                Something went wrong. please try again.
              </Alert>
            ) : null}
            {this.props.paymentApprovals.loading === true ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner animation="grow" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                {this.props.paymentApprovals.payments_approvals.length === 0 &&
                this.props.paymentApprovals.approval_status === false ? (
                  <>
                    <Alert variant="primary" style={{ textAlign: "center" }}>
                      There are no pending approvals.
                    </Alert>
                  </>
                ) : (
                  <>
                    {this.props.paymentApprovals.payments_approvals.map(
                      (approval) => {
                        let date = new Date(approval.updatedAt);

                        return (
                          <Card className="resellerCard">
                            <img
                              src="https://static.echojoy.in/logos/amazon.png"
                              class="rounded"
                            />

                            <Card.Body>
                              <Card.Text style={{ fontWeight: "600" }}>
                                <span style={{ marginRight: 20 }}>
                                  {approval.phonenumber}
                                </span>
                                <span style={{ marginRight: 20 }}>
                                  &#8377;
                                  {approval.paymentMethod.razorpayPaymentAmount}
                                </span>
                                <span>
                                  {approval.paymentMethod.transactionId}
                                </span>
                                <span style={{ float: "right" }}>
                                  {new Date(
                                    parseInt(approval.updatedAt)
                                  ).toLocaleString()}
                                </span>
                              </Card.Text>
                            </Card.Body>
                            <hr />

                            <Button
                              disabled={this.state.inProgress}
                              onClick={() => this.approvePayment(approval)}
                            >
                              {approval.loading === true ? (
                                <>
                                  <Spinner
                                    animation="grow"
                                    role="status"
                                    size="sm"
                                  >
                                    <span className="sr-only">Loading...</span>
                                  </Spinner>
                                  &nbsp;Capturing
                                </>
                              ) : (
                                <>Capture</>
                              )}
                            </Button>
                          </Card>
                        );
                      }
                    )}
                  </>
                )}
              </>
            )}
          </Col>
        </Row>
      </Container>
    );
    // }
  }
}

//export default ApproveAmountPage;

const mapStateToProps = (state) => {
  return {
    user: state.user,
    paymentApprovals: state.paymentApprovals,
  };
};

export default connect(mapStateToProps, {
  getWaitingApprovalPayments,
  updateApprovalStatus,
  closeAlert,
})(ApproveAmountPage);
