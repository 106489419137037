import axios from "axios";
import * as _ from "lodash";
import { getHostUrl } from '../utils/env';

const HOST_URL = getHostUrl();

export function getCardsByTimeIntervalAndProductId(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/report/ej/productcardslist`,
        {
          fromDate: data.fromDate,
          toDate: data.toDate,
          productId: data.productId,
          email: data.email,
          phone: data.phone,
          reportType: data.reportType
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        console.log(response);
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function getResellerCreditDebit(data) {
  console.log(data);
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/report/ej/resellerCreditDebitReport`,
        {
          fromDate: data.fromDate,
          toDate: data.toDate,
          phonenumber: data.phone
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function getResellerCards(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/report/ej/resellerCardsReport`,
        {
          fromDate: data.fromDate,
          toDate: data.toDate,
          phonenumber: data.phone
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function getCardsByProductAndNumber(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/report/ej/allCardsProductPhoneReport`,
        {
          fromDate: data.fromDate,
          toDate: data.toDate,
          productId: data.productId,
          phonenumber: data.phone,
          email: data.email
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function getLoadMoneyReportByNumber(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/report/ej/allCardOrdersPhoneReport`,
        {
          fromDate: data.fromDate,
          toDate: data.toDate,
          phone: data.phone,
          email: data.email
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function getBankCreditsReportApi(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/report/bankTransferCreditReport`,
        {
          fromDate: data.fromDate,
          toDate: data.toDate,
          email: data.email
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function getRazorPayCreditsReportApi(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/report/razorPayCreditReport`,
        {
          fromDate: data.fromDate,
          toDate: data.toDate
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function getBalanceAdjusmentReportApi(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/report/transactionCreditReport`,
        {
          fromDate: data.fromDate,
          toDate: data.toDate,
          transactionType: 'BALANCE_ADJUSTMENT'
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}
