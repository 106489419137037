import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Tabs, Tab } from "react-bootstrap";
import { getResellerCreditDebitStatus } from "../../../actions/reportsActions";
import { ButtonText, BaseReportTemplate } from "./BaseReportTemplate";

class ResellerCreditDebit extends React.Component {
  state = {
    startDate: new Date(),
    endDate: new Date(),
    phone: ""
  };

  generateReport = async (e) => {
    e.preventDefault();
    const startDate = this.state.startDate;
    const endDate =this.state.endDate;
    const apiObj = {
      fromDate: startDate,
      toDate: endDate,
      phone: this.state.phone,
      access_token: this.props.user.accessCode
    };
    const data = await this.props.getResellerCreditDebitStatus(apiObj);

    const url = data.data.url;
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    return (
      <>
        <Form>
          <div className="form-group">
            <label>Start Date</label>
            <DatePicker
              selected={this.state.startDate}
              onChange={this.handleStartDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <label>End Date</label>
            <DatePicker
              selected={this.state.endDate}
              onChange={this.handleEndDateChange}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              timeCaption="time"
              dateFormat="MMMM d, yyyy h:mm aa"
              className="form-control asd"
            />
          </div>
          <div className="form-group">
            <label>Phone Number</label>
            <input
              type="text"
              className="form-control"
              onChange={this.handlePhoneNumberChange}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block"
            onClick={this.generateReport}
          >
            Generate Report
          </button>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, { getResellerCreditDebitStatus })(ResellerCreditDebit);
