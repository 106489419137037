'use strict';
import React from 'react';
import PropTypes from 'prop-types';
import { Pagination } from 'react-bootstrap';
import '../../styles/pagination.scss';

class TransactionPagination extends React.Component {
	constructor(props) {
		super(props);
		this.state = { pager: {} };
	}

	componentWillMount() {
		if (this.props.items && this.props.items.length) {
			this.setPage(this.props.initialPage);
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.props.items !== prevProps.items) {
			this.setPage(this.props.initialPage);
		}
	}

	setPage(page) {
		let items = this.props.items.filter((card) => {
			return !card.parent && card.productId === '1544';
		});
		let pager = this.state.pager;

		// Revers the
		if (page < 1 || page > pager.totalPages) {
			return;
		}

		pager = this.getPager(items.length, page);

		let pageOfItems = items.slice(pager.startIndex, pager.endIndex + 1);

		this.setState({ pager: pager });

		this.props.onChangePage(pageOfItems);
	}

	getPager(totalItems, currentPage, pageSize) {
		currentPage = currentPage || 1;
		pageSize = pageSize || 10;
		let totalPages = Math.ceil(totalItems / pageSize);

		let startPage, endPage;
		if (totalPages <= 10) {
			startPage = 1;
			endPage = totalPages;
		} else {
			if (currentPage <= 6) {
				startPage = 1;
				endPage = 10;
			} else if (currentPage + 4 >= totalPages) {
				startPage = totalPages - 9;
				endPage = totalPages;
			} else {
				startPage = currentPage - 5;
				endPage = currentPage + 4;
			}
		}

		let startIndex = (currentPage - 1) * pageSize;
		let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);
		let pages = [ ...Array(endPage + 1 - startPage).keys() ].map((i) => startPage + i);

		return {
			totalItems: totalItems,
			currentPage: currentPage,
			pageSize: pageSize,
			totalPages: totalPages,
			startPage: startPage,
			endPage: endPage,
			startIndex: startIndex,
			endIndex: endIndex,
			pages: pages
		};
	}

	render() {
		let pager = this.state.pager;

		if (!pager.pages || pager.pages.length <= 1) {
			return null;
		}

		return (
			<Pagination>
				<Pagination.Item className={pager.currentPage === 1 ? 'disabled' : ''} onClick={() => this.setPage(1)}>
					First
				</Pagination.Item>
				<Pagination.Item
					className={pager.currentPage === 1 ? 'disabled' : ''}
					onClick={() => this.setPage(pager.currentPage - 1)}
				>
					Previous
				</Pagination.Item>
				{pager.pages.map((page, index) => (
					<Pagination.Item
						key={index}
						className={pager.currentPage === page ? 'active' : ''}
						onClick={() => this.setPage(page)}
					>
						{page}
					</Pagination.Item>
				))}
				<Pagination.Item
					className={pager.currentPage === pager.totalPages ? 'disabled' : ''}
					onClick={() => this.setPage(pager.currentPage + 1)}
				>
					Next
				</Pagination.Item>
				<Pagination.Item
					className={pager.currentPage === pager.totalPages ? 'disabled' : ''}
					onClick={() => this.setPage(pager.totalPages)}
				>
					Last
				</Pagination.Item>
			</Pagination>
		);
	}
}

const propTypes = {
	items: PropTypes.array.isRequired,
	onChangePage: PropTypes.func.isRequired,
	initialPage: PropTypes.number,
	pageSize: PropTypes.number
};

const defaultProps = {
	initialPage: 1
};

TransactionPagination.propTypes = propTypes;
TransactionPagination.defaultProps = defaultProps;

export default TransactionPagination;
