import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Image,
  FormControl,
  Form,
  Spinner
} from "react-bootstrap";
import { Link } from "react-router-dom";

import _ from "lodash";
import TransactionPagination from "./TransactionsPagination";
import { EjNavbar } from "../EjNavbar";
import "../../styles/transaction.scss";
import * as actions from "../../actions/cardActions";

class TransactionsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      cards: [],
      displayCard: null,
      pageOfTransactions: []
    };
    this.onChangePage = this.onChangePage.bind(this);
  }

  onChangePage(pageOfTransactions) {
    // update state with new page of items
    this.setState({ pageOfTransactions: pageOfTransactions });
  }

  UNSAFE_componentWillMount() {
    !_.get(this.props, "user.accessCode") && this.props.history.push("/login");
  }

  componentDidMount() {
    // Get the cards and details for this user
    if (_.get(this.props, "user.accessCode")) {
      this.props.actions.getCards({
        access_token: this.props.user.accessCode,
        number: this.props.user.phoneNumber
      });
    }
  }

  // componentDidUpdate(nextProps) {
  //   // If cards value have changed
  //   // if(!this.state.displayCard) { // This can lead to error if there is no card of the give product id
  //   //   let displayCard =  _.find(nextProps.cards.cards, {'productId': 9142, 'parent': true })
  //   //   this.setState({ displayCard })
  //   // }
  // }

  logout = async () => {
    this.props.logout().then(() => {
      this.props.history.push("/login");
    });
  };

  render() {
    // Move this outside
    let cards = _.get(this.props.cards, "cards");
    const { loading } = this.props.cards;

    if (loading) {
      <>
        <EjNavbar {...this.props} />
        <Container>
          <Row>
            <Col
              sm={{ span: 6, offset: 3 }}
              style={{
                marginTop: 30,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            </Col>
          </Row>
        </Container>
      </>;
    }
    return (
      <React.Fragment>
        <EjNavbar {...this.props} />
        <Container>
          <Row>
            <Col sm={{ span: 6, offset: 3 }} style={{ marginTop: 20 }}>
              {this.state.pageOfTransactions.map(card => {
                return (
                  <div className="card" key={card.cardId}>
                    <div>
                      <Image
                        src="https://static.echojoy.in/logos/amazon.png"
                        rounded
                      />
                      <span className="value">&#8377;{card.card_price}</span>
                      <CopyToClipboard text={card.pin_or_url} onCopy={() => {}}>
                        <span className="code">{card.pin_or_url || " "}</span>
                      </CopyToClipboard>
                    </div>
                    <div className="date">
                      {new Date(parseInt(card.updatedAt)).toLocaleString()}
                    </div>
                  </div>
                );
              })}
              <TransactionPagination
                items={cards}
                onChangePage={this.onChangePage}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

TransactionsPage.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  cards: PropTypes.array
};

function mapStateToProps(state) {
  return {
    user: state.user,
    cards: state.cards
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionsPage);
