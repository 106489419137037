import * as actionTypes from "../actions/resellersCardsBalancesActions";
import * as actionType from "../actions/loginActions";

const initialState = {
  loading: false,
  balances: [],
  error: null,
  totalCount: null,
  page: null,
  size: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    // phonenumber list
    case actionTypes.REQUEST_RESELLERS_CARDS_BALANCES:
      return {
        ...state,
        loading: true
      };
    case actionTypes.REQUEST_RESELLERS_CARDS_BALANCES_SUCCESS:
      return {
        ...state,
        loading: false,
        balances: action.resellerCards,
        totalCount: action.totalCount,
        page: action.page,
        size: action.size
      };

    case actionTypes.REQUEST_RESELLERS_CARDS_BALANCES_FAIL:
      return {
        ...state,
        loading: false,
        balances: []
      };

    case actionType.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}
