import * as actionTypes from "../actions/createResellerCardsActions";
import * as actionType from "../actions/loginActions";

const initialState = {
  loading: false,
  error: null,
  successMsg: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // phonenumber list
    case actionTypes.CREATE_RESELLER_CARDS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.CREATE_RESELLER_CARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        successMsg: action.data.data.message,
        error: null,
      };

    case actionTypes.CREATE_RESELLER_CARDS_FAILED:
      return {
        ...state,
        loading: false,
        successMsg: null,
        error: action.data.response.data.message,
      };

    case actionType.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}
