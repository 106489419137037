import React from "react";
import { connect } from "react-redux";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Button,
  Form,
  ListGroup,
  FormControl,
  Alert,
  Spinner
} from "react-bootstrap";
import { Link } from "react-router-dom";

import {
  addPhoneNumber,
  requestPhoneNumbers,
  createPhoneNumber,
  requestResellerOtp
} from "../../actions/phoneActions";
import { EjNavbar } from "../EjNavbar";
import PhoneNumber from "./PhoneNumber";
import "../../styles/phonelist.scss";
import { indianPhoneRegexValidation } from "../../utils/regx";

class PhoneCrudPage extends React.Component {
  state = {
    value: "",
    otpField: false,
    otpValue: "",
    error: false,
    errMsg: ""
  };

  UNSAFE_componentWillMount() {
    !_.get(this.props, "user.accessCode") && this.props.history.push("/login");
    this.props.createPhoneNumber({ accessCode: this.props.user.accessCode });
  }

  componentDidMount() {
    this.props.requestPhoneNumbers({
      resellerPhone: this.props.user.phoneNumber,
      accessCode: this.props.user.accessCode
    });
  }

  addNumber = async event => {
    event.preventDefault();

    if (this.state.otpValue !== "" && this.state.otpField === true) {
      if (this.state.otpValue.length != 6) {
        this.setState({
          error: true,
          errMsg: "please enter 6 digit OTP"
        });

        setTimeout(() => {
          this.setState({
            error: false,
            errMsg: ""
          });
        }, 4000);
        return;
      }
      await this.props.addPhoneNumber({
        phone: this.state.value,
        otp: this.state.otpValue,
        accessCode: this.props.user.accessCode
      });

      await this.setState({
        otpField: false,
        otpValue: "",
        value: "",
        error: null
      });
    } else if (
      this.state.value &&
      indianPhoneRegexValidation.test(this.state.value)
    ) {
      await this.props.requestResellerOtp({
        newResellerPhone: this.state.value,
        accessCode: this.props.user.accessCode
      });

      this.setState({
        otpField: true,
        error: false
      });
    } else {
      this.setState({
        error: true,
        errMsg: "Please enter valid 10 digit indian phone number"
      });

      setTimeout(() => {
        this.setState({
          error: false,
          errMsg: ""
        });
      }, 4000);
    }
  };

  handleOtpChange = event => {
    this.setState({
      otpValue: event.target.value
    });
  };

  handleChange = event => {
    const re = /^[0-9\b]+$/;
    const value = event.target.value;
    if (value === "" || re.test(value)) {
      this.setState({ value });
    }
  };

  render() {
    const {
      phone_numbers,
      loading,
      deleteBtnLoading,
      addBtnLoading
    } = this.props.phoneNumbers;

    return (
      <>
        <EjNavbar {...this.props} />
        <Container>
          <Row>
            <Col sm={{ span: 6, offset: 3 }} style={{ marginTop: 30 }}>
              <h2>Phonenumbers List</h2>
              {this.state.error === true ? (
                <Alert variant="danger">{this.state.errMsg}</Alert>
              ) : null}
              <Form onSubmit={e => this.addNumber(e)}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter New Phonenumber to link"
                    value={this.state.value}
                    onChange={this.handleChange}
                  />
                  <Form.Text className="text-muted">
                    Please enter 10 digit indian number
                  </Form.Text>
                  {this.state.otpField === true ? (
                    <>
                      <Form.Label>OTP</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter 6 digit OTP"
                        value={this.state.otpValue}
                        onChange={this.handleOtpChange}
                      />
                      <Form.Text className="text-muted">
                        Enter 6 digit OTP
                      </Form.Text>
                    </>
                  ) : null}
                  <Button variant="primary" block onClick={this.addNumber}>
                    {addBtnLoading === true ? (
                      <span style={{ marginRight: "10px" }}>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </span>
                    ) : null}
                    Add Number
                  </Button>
                </Form.Group>
              </Form>
              {this.props.flashMsg.flashMsg !== "" &&
              this.props.flashMsg.variant !== "" ? (
                <Alert variant={this.props.flashMsg.variant}>
                  {this.props.flashMsg.flashMsg}
                </Alert>
              ) : null}

              {this.props.phoneNumbers.error !== null &&
              this.props.phoneNumbers.error === "INVALID_PHONE_NUMBER" ? (
                <Alert variant="danger">
                  Invalid phone number! Please enter valid phone number
                </Alert>
              ) : null}

              {deleteBtnLoading === true ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : null}

              {!loading ? (
                <>
                  {phone_numbers.map(phone_number => {
                    return (
                      <PhoneNumber
                        phone_number={phone_number}
                        key={phone_number.id}
                        deleteBtnLoading={deleteBtnLoading}
                      />
                    );
                  })}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    phoneNumbers: state.phoneNumbers,
    user: state.user,
    flashMsg: state.flashMsg
  };
};

export default connect(mapStateToProps, {
  addPhoneNumber,
  requestPhoneNumbers,
  createPhoneNumber,
  requestResellerOtp
})(PhoneCrudPage);
