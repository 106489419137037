import * as _ from "lodash";
import {
  getResellerActivityAPI,
  getResellerDebitsAPI,
  getAllUserActivityAPI,
  getResellerDebitsIScroll
} from "../apis/resellerActivity";
import {getUserByCategoryAPI} from "../apis/users";

export const REQUEST_RESELLER_ACTIVITY = "REQUEST_RESELLER_ACTIVITY";
export const REQUEST_RESELLER_ACTIVITY_FAILED =
  "REQUEST_RESELLER_ACTIVITY_FAILED";
export const REQUEST_RESELLER_ACTIVITY_SUCCESS =
  "REQUEST_RESELLER_ACTIVITY_SUCCESS";

export const REQUEST_RESELLER_DEBITS = "REQUEST_RESELLER_DEBITS";
export const REQUEST_RESELLER_DEBITS_FAILED = "REQUEST_RESELLER_DEBITS_FAILED";
export const REQUEST_RESELLER_DEBITS_SUCCESS =
  "REQUEST_RESELLER_DEBITS_SUCCESS";
export const REQUEST_RESELLER_DEBITS_RESET = "REQUEST_RESELLER_DEBITS_RESET";

export const REQUEST_ALL_USER_ACTIVITY = "REQUEST_ALL_USER_ACTIVITY";
export const REQUEST_ALL_USER_ACTIVITY_FAILED =
  "REQUEST_ALL_USER_ACTIVITY_FAILED";
export const REQUEST_ALL_USER_ACTIVITY_SUCCESS =
  "REQUEST_ALL_USER_ACTIVITY_SUCCESS";


export const REQUEST_USER_DETAILS = "REQUEST_USER_DETAILS";
export const REQUEST_USER_DETAILS_FAILED = "REQUEST_USER_DETAILS_FAILED";
export const REQUEST_USER_DETAILS_SUCCESS = "REQUEST_USER_DETAILS_SUCCESS";

export const USER_DETAILS_SWITCH_TAB = "USER_DETAILS_SWITCH_TAB";



// example of a thunk using the redux-thunk middleware
export const getResellerActivity = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_RESELLER_ACTIVITY });
  return getResellerActivityAPI(data)
    .then((result) => {
      const resellerActivityArr = _.sortBy(result.data.data, "createdAt");
      dispatch({
        type: REQUEST_RESELLER_ACTIVITY_SUCCESS,
        resellerActivity: result.data.data,
        totalCount: result.data.totalCount,
        page: result.data.page,
        size: result.data.size,
      });
    })
    .catch(() => {});
};

export const getResellerDebits = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_RESELLER_DEBITS });
  return getResellerDebitsIScroll(data)
    .then((result) => {
      dispatch({
        type: REQUEST_RESELLER_DEBITS_SUCCESS,
        resellerDebits: result.data.data,
        lastEvaluatedKey: result.data.lastEvaluatedKey
      });
    })
    .catch(() => {
      console.log('Failed')
    });
};

// all user activity action
export const getAllUserActivity = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_ALL_USER_ACTIVITY });
  return getAllUserActivityAPI(data)
    .then((result) => {
      dispatch({
        type: REQUEST_ALL_USER_ACTIVITY_SUCCESS,
        Items: result.data.Items,
        lastEvaluatedKey: result.data.LastEvaluatedKey
      });
    })
    .catch((e) => {
      if (e.response.status === 500) {
        dispatch({
          type: REQUEST_ALL_USER_ACTIVITY_FAILED,
          error: "Something went wrong",
        });
      } else {
        dispatch({
          type: REQUEST_ALL_USER_ACTIVITY_FAILED,
          error: "Something went wrong",
        });
      }
    });
};

//User Details Action
export const getUserByCategory = (data) => async (dispatch) => {

  dispatch({ type: REQUEST_USER_DETAILS });
  return getUserByCategoryAPI(data)
    .then((result) => {
      dispatch({
        type: REQUEST_USER_DETAILS_SUCCESS,
        data:result.data.data,
        lastEvaluatedKey: result.data.lastEvaluatedKey,
        usertype: data.usertype,
      });
    })
    .catch((e) => {
      if (e.response.status === 500) {
        dispatch({
          type: REQUEST_USER_DETAILS_FAILED,
          error: "Something went wrong",
        });
      } else {
        dispatch({
          type: REQUEST_USER_DETAILS_FAILED,
          error: "Something went wrong",  
        });
      }
    });
};

export const userDetailsSwitchTab = () => {
  return {
    type: USER_DETAILS_SWITCH_TAB,
  };
};

