import * as actionTypes from "../actions/flashMsgActions";
import * as actionType from "../actions/loginActions";
const initialState = {
  //loading: false,
  flashMsg: "",
  variant: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_FLASH_MESSAGE:
      return {
        ...state,
        flashMsg: action.flashMsg,
        variant: action.variant
      };
    case actionTypes.DELETE_FLASH_MESSAGE:
      return {
        ...state,
        flashMsg: "",
        variant: ""
      };

    case actionType.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
};
