/* eslint require-atomic-updates: off */
import axios from "axios";
import { getHostUrl } from '../utils/env';

const HOST_URL = getHostUrl();

export const getResellerOtp = async data => {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/resellers/requestotp`,
        {
          new_phonenumber: data.newResellerPhone
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.accessCode}`
          }
        }
      )
      .then(response => {
        res(response.data);
      })
      .catch(error => {
        rej(new Error(error));
      })
  );
};

export const getPhoneNumbers = async data => {
  return new Promise((res, rej) =>
    axios
      .get(`${HOST_URL}/resellers/getnumbers`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${data.accessCode}`
        }
      })
      .then(response => {
        res(response.data);
      })
      .catch(error => {
        rej(new Error(error));
      })
  );
};

export const addPhoneNumberAPI = async data => {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/resellers/addnumber`,
        {
          //   phonenumber: data.resellerPhone,
          new_phonenumber: data.phone,
          otp: data.otp
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.accessCode}`
          }
        }
      )
      .then(response => {
        res(response.data);
      })
      .catch(error => {
        rej(new Error(error));
      })
  );
};

export const deletePhoneNumberAPI = async data => {
  return new Promise((res, rej) =>
    axios
      .delete(`${HOST_URL}/resellers/removenumber/${data.removeNumber}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${data.accessCode}`
        }
      })
      .then(response => {
        res(response.data);
      })
      .catch(error => {
        rej(new Error(error));
      })
  );
};

export const createPhoneNumberAPI = async data => {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/resellers/create`,
        {
          phonenumber: data.resellerPhone
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.accessCode}`
          }
        }
      )
      .then(response => {
        res(response.data);
      })
      .catch(error => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
};
