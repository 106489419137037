import * as actionTypes from "../actions/resellerActivityActions";
import * as actionType from "../actions/loginActions";

const initialState = {
  loading: false,
  user_activity: [],
  error: null,
  lastEvaluatedKey: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    // phonenumber list
    case actionTypes.REQUEST_ALL_USER_ACTIVITY:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actionTypes.REQUEST_ALL_USER_ACTIVITY_SUCCESS:
      console.log(action)
      return {
        ...state,
        loading: false,
        user_activity: [...state.user_activity, ...action.Items],
        lastEvaluatedKey: action.lastEvaluatedKey
      };

    case actionTypes.REQUEST_ALL_USER_ACTIVITY_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actionType.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}
