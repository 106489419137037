import React from "react";
import { connect } from "react-redux";
import { ListGroup, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { CopyToClipboard } from "react-copy-to-clipboard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobile } from "@fortawesome/free-solid-svg-icons";

class ResellerCardActivity extends React.Component {
  render() {
    return (
      <>
        <Card className="resellerCard">
          <img
            src="https://static.echojoy.in/logos/amazon.png"
            class="rounded"
          />
          <Card.Body>
            <Card.Text style={{ fontWeight: "600" }}>
              <span>
                {new Date(parseInt(this.props.card.orderedAt)).toLocaleString()}
              </span>

              <span style={{ marginLeft: "10px" }}>
                {this.props.card.pin_or_url}
              </span>
              
              <span style={{ float: "right" }}>
                &#8377;{this.props.card.card_balance}
              </span>
            </Card.Text>
          </Card.Body>
          <hr />
          <Card.Link href="#" className="addMoney-btn">
            <Link
              to={`${this.props.router.location.pathname}/reselleractivity/${this.props.card.phonenumber}`}
            >
              Activity
            </Link>
          </Card.Link>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    cards: state.cards,
    phoneNumbers: state.phoneNumbers,
    router: state.router
    // loading: state.phoneNumbers.loading
  };
};

export default connect(mapStateToProps, null)(ResellerCardActivity);
