import React from "react";
import { connect } from "react-redux";
import { getAllUserActivity } from "../../../actions/resellerActivityActions";
import { Spinner, Alert, Card, Row, Col, Container } from "react-bootstrap";
import debounce from "lodash.debounce";

class AllUserActivity extends React.Component {
  constructor(props) {
    super(props);

    window.onscroll = debounce(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop ===
        document.documentElement.offsetHeight
      ) {
        this.loadUserActivity();
      }
    }, 100);
  }

  componentDidMount() {
    this.props.getAllUserActivity({
      limit: 10,
      access_token: this.props.user.accessCode,
      lastEvaluatedKey: null,
    });
  }

  loadUserActivity() {
    console.log('Loading new activity', this.props.userActivity)
    if (this.props.userActivity.lastEvaluatedKey !== undefined) {
      this.props.getAllUserActivity({
        limit: 10,
        access_token: this.props.user.accessCode,
        lastEvaluatedKey: this.props.userActivity.lastEvaluatedKey,
      });
    }
  }

  render() {

    const { loading, user_activity } = this.props.userActivity;

    if (user_activity.length === 0 && loading === false) {
      return (
        <Container>
          <Row>
            <Col sm={{ span: 6, offset: 3 }} style={{ marginTop: 30 }}>
              <h5 className="element-header">User Activity</h5>
              <Alert variant="primary" style={{ textAlign: "center" }}>
                No User Activity
              </Alert>
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <Container>
        <Row>
          <Col sm={{ span: 8, offset: 2 }} style={{ marginTop: 30 }}>
            <h5 className="element-header">User Activity</h5>
            {this.props.userActivity.user_activity.map((activity) => {
              return (
                <>
                  <Card className="resellerCard">
                    {activity.brandType === "AMAZON" ? (
                      <img
                        src="https://static.echojoy.in/logos/amazon.png"
                        class="rounded"
                      />
                    ) : (
                      <img
                        src="https://static.echojoy.in/logos/flipkart.png"
                        class="rounded"
                      />
                    )}
                    <Card.Body>
                      <Card.Text style={{ fontWeight: "600" }}>
                        <span>
                          {new Date(
                            parseInt(activity.activitytime)
                          ).toLocaleString()}
                        </span>
                        {activity.transactionType ? (
                          <span style={{ marginLeft: "10px" }}>
                            {activity.transactionType}
                          </span>
                        ) : (
                          <span style={{ marginLeft: "10px" }}>
                            {activity.eventType}
                          </span>
                        )}
                        <span style={{ marginLeft: "10px" }}>
                          &#8377;
                          {activity.value &&
                            parseFloat(activity.value).toFixed(2)}
                        </span>
                        <span style={{ float: "right" }}>
                          {activity.parentCardBalance ? (
                            <>
                              {" "}
                              &#8377;
                              {activity.parentCardBalance &&
                                parseFloat(activity.parentCardBalance).toFixed(
                                  2
                                )}
                            </>
                          ) : null}
                        </span>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </>
              );
            })}

            {this.props.userActivity.loading && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            )}

            {this.props.userActivity.error && (
              <Alert variant="danger" style={{ textAlign: "center" }}>
                Something Went Wrong
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userActivity: state.allUserActivity,
  };
};

export default connect(mapStateToProps, { getAllUserActivity })(
  AllUserActivity
);
