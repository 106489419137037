import React from "react";
import { connect } from "react-redux";
import { Spinner, Alert, Card, Row, Col, Pagination, Button } from "react-bootstrap";
import {
  getResellerActivity,
  getResellerDebits
} from "../../actions/resellerActivityActions";
import { getCards } from "../../actions/cardActions";
import ResellerCard from "./ResellerCard";
import "../../styles/resellerActivity.scss";

class ResellerActivity extends React.Component {

  componentWillMount() {
      console.log(this.props)
      if (this.props.brandcard && this.props.brandcard.productId.toString() === '9142') {
        this.props.getResellerActivity({
          accessCode: this.props.user.accessCode,
          phone: this.props.brandcard.phonenumber,
          pageNumber: 1,
          brandType: "AMAZON"
        });

        this.props.getResellerDebits({
          access_token: this.props.user.accessCode,
          number: this.props.brandcard.phonenumber,
          pageNumber: 1,
          productId: "1544"
        });
      }

      if (this.props.brandcard &&  this.props.brandcard.productId.toString() === '9146') {
        this.props.getResellerActivity({
          accessCode: this.props.user.accessCode,
          phone: this.props.brandcard.phonenumber,
          pageNumber: 1,
          brandType: "FLIPKART"
        });

        this.props.getResellerDebits({
          access_token: this.props.user.accessCode,
          number: this.props.brandcard.phonenumber,
          pageNumber: 1,
          productId: "9259"
        });
      }

      if (this.props.brandcard && this.props.brandcard.productId.toString() === '9260') {
        this.props.getResellerActivity({
          accessCode: this.props.user.accessCode,
          phone: this.props.brandcard.phonenumber,
          pageNumber: 1,
          brandType: "AMAZON_SV"
        });

        this.props.getResellerDebits({
          access_token: this.props.user.accessCode,
          number: this.props.brandcard.phonenumber,
          pageNumber: 1,
          productId: "6861"
        });
      }

    // this.props.getResellerDebits({
    //   access_token: this.props.user.accessCode,
    //   number: this.props.brandcard.phonenumber,
    //   pageNumber: 1,
    //   productId: this.props.brandcard.productId
    // });
  }

  getResellerActivityPage = pageNumber => {
    if (this.props.brandcard.productId === 9142) {
      this.props.getResellerActivity({
        accessCode: this.props.user.accessCode,
        phone: this.props.brandcard.phonenumber,
        pageNumber: pageNumber,
        brandType: "AMAZON"
      });
    }
    if (this.props.brandcard.productId === 9260) {
      this.props.getResellerActivity({
        accessCode: this.props.user.accessCode,
        phone: this.props.brandcard.phonenumber,
        pageNumber: pageNumber,
        brandType: "AMAZON_SV"
      });
    }
    if (this.props.brandcard.productId === 9146) {
      this.props.getResellerActivity({
        accessCode: this.props.user.accessCode,
        phone: this.props.brandcard.phonenumber,
        pageNumber: 1,
        brandType: "FLIPKART"
      });
    }
  };

  getResellerDebitPage = pageNumber => {
    if (this.props.brandcard.productId === 9142) {
      this.props.getResellerDebits({
        access_token: this.props.user.accessCode,
        number: this.props.brandcard.phonenumber,
        pageNumber: pageNumber,
        productId: "1544"
      });
    }
    if (this.props.brandcard.productId === 9260) {
      this.props.getResellerDebits({
        access_token: this.props.user.accessCode,
        number: this.props.brandcard.phonenumber,
        pageNumber: pageNumber,
        productId: "6861"
      });
    }
    if (this.props.brandcard.productId === 9146) {
      this.props.getResellerDebits({
        access_token: this.props.user.accessCode,
        number: this.props.brandcard.phonenumber,
        pageNumber: 1,
        productId: "9259"
      });
    }
  };

  paginationItems = () => {
    const {
      page,
      size,
      totalCount,
      reseller_activity
    } = this.props.resellerActivity;
    let totalPages = Math.ceil(totalCount / 100);
    let active = page;
    let items = [];

    for (let number = 1; number <= totalPages; number++) {
      if (reseller_activity.length !== 0) {
        items.push(
          <Pagination.Item
            key={number}
            active={number == active}
            onClick={() => this.getResellerActivityPage(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }

    // items.push(
    //   <Pagination.Next
    //     onClick={() => this.getResellerActivityPage(parseInt(page) + 1)}
    //   />
    // );

    // items.push(
    //   <Pagination.Last
    //     onClick={() => this.getResellerActivityPage(parseInt(page) + 1)}
    //   />
    // );
    return <>{items}</>;
  };

  paginationDebitsItems = () => {
    const {
      page,
      size,
      totalCount,
      reseller_debits
    } = this.props.resellerDebit;
    let totalPages = Math.ceil(totalCount / 100);
    let active = page;
    let items = [];

    for (let number = 1; number <= totalPages; number++) {
      if (reseller_debits.length !== 0) {
        items.push(
          <Pagination.Item
            key={number}
            active={number == active}
            onClick={() => this.getResellerDebitPage(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    }

    // items.push(
    //   <Pagination.Next
    //     onClick={() => this.getResellerDebitPage(parseInt(page) + 1)}
    //   />
    // );

    // items.push(
    //   <Pagination.Last
    //     onClick={() => this.getResellerDebitPage(parseInt(page) + 1)}
    //   />
    // );
    return <>{items}</>;
  };

  getActivity = () => {
    const { loading, reseller_activity } = this.props.resellerActivity;
  
    if (reseller_activity.length === 0) {
      return (
        <Col sm={6}>
          <h5 className="element-header">Credit History</h5>
          <Alert variant="primary" style={{ textAlign: "center" }}>
            No Credit History
          </Alert>
        </Col>
      );
    } else {
      return (
        <>
          <Col sm={6}>
            <h5 className="element-header">Credit History</h5>
            {reseller_activity.map(activity => {
              return (
                <>
                  <Card className="resellerCard">
                    {activity.brandType === "AMAZON" ? (
                      <img
                        src="https://static.echojoy.in/logos/amazon.png"
                        class="rounded"
                      />
                    ) : null }
                    {activity.brandType === "AMAZON_SV" ?(
                      <img
                        src="https://static.echojoy.in/logos/amazonsv.jpg"
                        class="rounded"
                      />
                    ): null}
                    {activity.brandType === "FLIPKART" ?(
                      <img
                        src="https://static.echojoy.in/logos/flipkart.png"
                        class="rounded"
                      />
                    ): null}
                    <Card.Body>
                      <Card.Text style={{ fontWeight: "600" }}>
                        <span>
                          {new Date(
                            parseInt(activity.activitytime)
                          ).toLocaleString()}
                        </span>
                        {activity.transactionType ? (
                          <span style={{ marginLeft: "10px" }}>
                            {activity.transactionType}
                          </span>
                        ) : (
                          <span style={{ marginLeft: "10px" }}>
                            {activity.eventType}
                          </span>
                        )}
                        <span style={{ marginLeft: "10px" }}>
                          &#8377;{activity.value && parseFloat(activity.value).toFixed(2)}
                        </span>
                        <span style={{ float: "right" }}>
                          {activity.parentCardBalance ? (
                            <> &#8377;{activity.parentCardBalance && parseFloat(activity.parentCardBalance).toFixed(2)}</>
                          ) : null}
                        </span>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </>
              );
            })}
            <Pagination>{this.paginationItems()}</Pagination>
          </Col>
        </>
      );
    }
  };



  getDebits = () => {
    const { reseller_debits, lastEvaluatedKey, loading } = this.props.resellerDebit;
    
    if (reseller_debits.length === 0) {
      return (
        <Col sm={6}>
          <h5 className="element-header">Debit History</h5>
          <Alert variant="primary" style={{ textAlign: "center" }}>
            No Debit History
          </Alert>
        </Col>
      )
    } else {
      return (
        <Col sm={6}>
          <h5 className="element-header">Debit History</h5>
          {reseller_debits.map(card => {
            return (
              <>
                <Card className="resellerCard">
                  {card.productId === "1544" ? (
                    <img
                      src="https://static.echojoy.in/logos/amazon.png"
                      class="rounded"
                    />
                  ) : null }
                  {card.productId === '6861' ? (
                    <img
                      src="https://static.echojoy.in/logos/amazonsv.jpg"
                      class="rounded"
                    />
                  ): null }

                  {card.productId === '9259' ? (
                    <img
                      src="https://static.echojoy.in/logos/flipkart.png"
                      class="rounded"
                    />
                  ): null }

                  <Card.Body>
                    <Card.Text style={{ fontWeight: "600" }}>
                      <span>
                        {new Date(parseInt(card.orderedAt)).toLocaleString()}
                      </span>

                      <span style={{ marginLeft: "10px" }}>
                        {card.pin_or_url}
                      </span>

                      <span style={{ marginLeft: "10px" }}>
                        &#8377;{card.card_balance && parseFloat(card.card_balance).toFixed(2)}
                      </span>
                      {/* <hr /> */}
                      <span style={{ float: "right" }}>
                        {card.parentCardBalance ? (
                          <>
                            &#8377;{card.parentCardBalance && parseFloat(card.parentCardBalance).toFixed(2)}
                          </>
                        ) : null}
                      </span>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </>
            );
          })}
          <br/>
          {
            !loading ?
              lastEvaluatedKey &&
              <Button block size="lg" variant="info" onClick={() => this.props.getResellerDebits({
                access_token: this.props.user.accessCode,
                number: this.props.brandcard.phonenumber,
                productId: this.props.brandcard.productId === 9142 ? '1544' : this.props.brandcard.productId === 9260 ? '6861' : '9259',
                lastEvaluatedKey: lastEvaluatedKey
              })}> Load More ... </Button>
            :
            <Col sm={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            </Col>
          }
          
          <br/>
        </Col>
      );
    }
  };
  render() {
    const { loading, reseller_activity, lastEvaluatedKey } = this.props.resellerActivity;

    const debitLoading = this.props.resellerDebit.loading;

    return (
      <>
        <Row>
          {!loading ? (
            <>{this.getActivity()}</>
          ) : (
            <Col sm={6}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            </Col>
          )}
          <>{this.getDebits()}</>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    resellerActivity: state.resellerActivity,
    // cards: state.cards,
    resellerDebit: state.resellerDebit
  };
};

export default connect(mapStateToProps, {
  getResellerActivity,
  getCards,
  getResellerDebits
})(ResellerActivity);
