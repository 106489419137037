import * as actions from "../actions/cardActions";
import objectAssign from "object-assign";
import * as actionType from "../actions/loginActions";

const LoadingStatus = {};

const initialState = {
  cards: [],
  loading: false,
  error: false,
  success: false,
  loading: false,
  totalCount: null,
  page: null,
  size: null
};

export default function cardsReducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    case actions.ADD_MONEY_TO_CARD: 
      return {...state, loading: true}
    case actions.RESET_FORM_DATA: 
      return {...state, loading: false, success: false, error: false}
    case actions.ADD_MONEY_TO_CARD_SUCCESS: 
      return {...state, error: false, loading: false, success: true}
    case actions.ADD_MONEY_TO_CARD_FAIL: 
      return {...state, error: true, loading: false, success: false}
    case actions.REQUEST_CARDS:
      return { ...state, loading: true };
    case actions.REQUEST_CARDS_FAILED:
      return { ...state, loading: false };
    case actions.REQUEST_CARDS_SUCCESS:
      return {
        ...state,
        cards: action.cards,
        loading: false,
        totalCount: action.totalCount,
        page: action.page,
        size: action.size
      };
    case actionType.LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
