/* eslint-disable import/no-named-as-default */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Route, Switch, Redirect } from "react-router-dom";
import { hot } from "react-hot-loader";
import _ from "lodash";

import GetCardsPage from "./containers/GetCardsPage";
import DashboardPage from "./containers/DashboardPage";
import TransactionsPage from "./containers/TransactionsPage";
import LoginPage from "./containers/LoginPage";
import LogoutPage from "./containers/LogoutPage";
import PhoneCrudPage from "./containers/PhoneCrudPage";
import PaymentsPage from "./containers/PaymentsPage";
import LoadResellerMoney from "./containers/LoadResellerMoney";
import NotFoundPage from "./NotFoundPage";
import UserActivity from "./containers/UserActivity";
import EjNavbar from "./EjNavbar";
import ResellerCardsBalances from "./containers/ResellerCardsBalances";
import ResellerCardBalance from "./containers/ResellerCardBalance";
import Balances from "./containers/Balance";
import CreateResellerCards from "./containers/CreateResellerCards";
import Reports from "./containers/Reports";
import ApproveAmountPage from "./containers/ApproveAmountPage";
import AllUserActivity from "./containers/AllUserActivity/AllUserActivity";
import PaymentLinks from "./containers/PaymentLinks/PaymentLinks";
import UsersTable from "./containers/UsersTable";

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

const NoMatch = ({ location }) => (
  <div>
    <h3>
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

const PrivateRoute = ({ component: Component, auth, ...props }) => {
  return (
    <Route
      {...props}
      render={(props) =>
        auth === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            // to="/"
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const PublicRoute = ({ component: Component, auth, ...props }) => {
  return (
    <Route
      {...props}
      render={(props) =>
        auth === false ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

class App extends React.Component {
  render() {

    let auth = false;
    if(this.props.user) { auth = this.props.user.isAuthenticated }

    return (
      <>
        <EjNavbar />
        <Switch>
          <PrivateRoute
            exact
            path="/"
            auth={auth}
            component={DashboardPage}
          />
          <PrivateRoute
            exact
            path="/get-cards"
            auth={auth}
            component={GetCardsPage}
          />
          <PublicRoute
            auth={auth}
            path="/login"
            component={LoginPage}
          />
          {/* <PrivateRoute
            auth={auth}
            path="/useractivity"
            component={UserActivity}
          /> */}
          <PrivateRoute
            auth={auth}
            path="/resellercardsbalances"
            component={ResellerCardsBalances}
          />
          <PrivateRoute
            auth={auth}
            path="/createresellercards"
            component={CreateResellerCards}
          />
          <PrivateRoute
            auth={auth}
            path="/balance"
            component={Balances}
          />
          <PrivateRoute
            auth={auth}
            path="/reports"
            component={Reports}
          />
          <PrivateRoute
            auth={auth}
            path="/approveamount"
            component={ApproveAmountPage}
          />
          <PrivateRoute
            auth={auth}
            path="/alluseractivity"
            component={AllUserActivity}
          />
           <PrivateRoute
            auth={auth}
            path="/userdetails"
            component={UsersTable}
          />
          <PrivateRoute
            auth={auth}
            path="/paymentlinks"
            component={PaymentLinks}
          />
          <PrivateRoute
            auth={auth}
            path="/:cardId"
            component={LoadResellerMoney}
          />
          {/* <Route path="/logout" component={LogoutPage} />
          <Route path="/useractivity" component={UserActivity} />
          <Route path="/:cardId" component={LoadResellerMoney} /> */}
          <Route component={NoMatch} />
        </Switch>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default hot(module)(connect(mapStateToProps, null)(App));
