import React from "react";
import { connect } from "react-redux";
import { 
  getUserByCategory,
  userDetailsSwitchTab 
} from "../../../src/actions/resellerActivityActions";
import { Spinner, Alert, Card, Row, Col, Container,Form ,Button} from "react-bootstrap";
import "../../styles/transaction.scss";
import "../../styles/pagination.scss";

class UsersTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userTypes:[
        {
         usertype: "USER_RESELLER",
        },
        {
          usertype: "USER_ADMIN",
         },
         {
          usertype: "USER_CUSTOMER",
         }
      ],
      userDetailsIndex: 0,
    }
   }

   loadUserDetails() {
    
    const type = this.state.userTypes[this.state.userDetailsIndex];
    
    if(this.props.userDetails.lastEvaluatedKey !== undefined) {
      this.props.getUserByCategory({
        limit:20,
        access_token: this.props.user.accessCode,
        lastEvaluatedKey: this.props.userDetails.lastEvaluatedKey,
        usertype: type.usertype
      })
    }
   }

   componentDidMount() {
    const type = this.state.userTypes[this.state.userDetailsIndex];

    this.props.getUserByCategory({
      limit:20,
      access_token: this.props.user.accessCode,
      lastEvaluatedKey: null,
      usertype: type.usertype
    });
  }

  handleUserSelect = async(event) => {
    
    await this.setState({userDetailsIndex:event.target.value.toString() });
      
    const type = this.state.userTypes[this.state.userDetailsIndex];

    await this.props.userDetailsSwitchTab();

    await this.props.getUserByCategory({
      limit:20,
      access_token: this.props.user.accessCode,
      lastEvaluatedKey: null,
      usertype: type.usertype
    });
  };

  render() {

    const { loading, user_details,error,lastEvaluatedKey } = this.props.userDetails;

    const type = this.state.userTypes[this.state.userDetailsIndex];

    if (user_details.length === 0 && loading === false && error === false) {
      return (
        <Container>
          <Row>
            <Col sm={{ span: 6, offset: 3 }} style={{ marginTop: 30 }}>
              <h5 className="element-header">User Details</h5>

              <Form.Group>
                <Form.Label>Select Brand</Form.Label>
                <div className="inputSelect">
                  {this.state.userTypes.map((details, index) => (
                    <Form.Check
                      onClick={this.handleUserSelect}
                      htmlFor={`${ details && details.usertype}`}
                      id={`${details.usertype}`}
                      disabled={this.state.switchDisabled}
                      type={"radio"}
                      name="activeBrand"
                      label={`${details.usertype}`}
                      value={`${index}`}
                      checked={type.usertype === details.usertype}
                    />
                  ))}
                </div>
              </Form.Group>

              <Alert variant="primary" style={{ textAlign: "center" }}>
                No User Details
              </Alert>
            </Col>
          </Row>
        </Container>
      );
    }
    return (
      <Container>
        <Row>
          <Col sm={{ span: 8, offset: 2 }} style={{ marginTop: 30 }}>
            <h5 className="element-header">User Details</h5>
            <Form.Group>
                <Form.Label>Select User Type</Form.Label>
                <div className="inputSelect">
                  {this.state.userTypes.map((details, index) => (
                    <Form.Check
                      onClick={this.handleUserSelect}
                      htmlFor={`${details.usertype}`}
                      id={`${details.usertype}`}
                      disabled={this.state.switchDisabled}
                      type={"radio"}
                      name="activeBrand"
                      label={`${details.usertype}`}
                      value={`${index}`}
                      checked={type.usertype === details.usertype}
                    />
                  ))}
                </div>
              </Form.Group>
           
            {this.props.userDetails && this.props.userDetails.user_details.map((details,index) => {
              return (
                <>
                  <Card className="resellerCard">
                    <Card.Body>
                      <Card.Text style={{ fontWeight: "600" }}>
                        <span>{index+1}.</span>
                        <span style={{ marginLeft: "20px" }}>
                          {details.id}
                        </span>
                        <span style={{ marginLeft: "20px" }}>
                            {new Date(
                             parseInt(details.createdAt)
                             ).toLocaleString()}
                        </span>
                        <span style={{ marginLeft: "20px" }}>
                          &#8377; {details.balance}
                        </span>
                        <span style={{ marginLeft: "20px" }}>
                          {details.userStatus}
                        </span>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </>
              );
            })}

              <br />
              {!loading ? (
                lastEvaluatedKey && (
                  <Button
                    block
                    size="lg"
                    variant="info"
                    onClick={() => this.loadUserDetails()}
                  >
                    {" "}
                    Load More ...{" "}
                  </Button>
                )
              ) : (
                <Col sm={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Spinner animation="grow" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                </Col>
              )}

              <br />

            {this.props.userDetails.error === true ? (
              <Alert variant="danger" style={{ textAlign: "center" }}>
                Something Went Wrong
              </Alert>
            ):null}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    userDetails: state.usersReducer,
  };
};

export default connect(mapStateToProps, { getUserByCategory,userDetailsSwitchTab })(
  UsersTable
);
