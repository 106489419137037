import React from "react";
import { connect } from "react-redux";
import Table from "react-bootstrap/Table";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  ListGroup,
  FormControl,
  Alert,
  Spinner
} from "react-bootstrap";

import { getResellerActivity } from "../../actions/resellerActivityActions";
import ResellerActivity from "./ResellerActivity";
import "../../styles/resellerActivity.scss";

class UserActivity extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // this.props.getResellerActivity({
    //   accessCode: this.props.user.accessCode,
    //   phone: this.props.user.phoneNumber
    // });
  }

  render() {
    const reseller = this.props.data.find(
      reseller => reseller.phonenumber === this.props.match.params.phone
    );

    const brandcard = this.props.data.find(
      reseller =>
        reseller.productId === parseInt(this.props.match.params.brandType)
    );

    if (reseller) {
      return (
        <>
          <h2 style={{ textAlign: "center", marginTop: "15px" }}>
            Reseller Activity: {reseller.phonenumber}
          </h2>
          <ResellerActivity data={reseller} brandcard={brandcard} />
        </>
      );
    } else {
      return <div>loading</div>;
    }
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    resellerActivity: state.resellerActivity
  };
};

export default connect(mapStateToProps, { getResellerActivity })(UserActivity);
