// import React, { Component } from "react";
// import PropTypes from "prop-types";
// import { ConnectedRouter } from "connected-react-router";
// import { Provider } from "react-redux";
// import { PersistGate } from "redux-persist/integration/react";
// import { persiststore } from "../store/index";
// import App from "./App";
// import "bootstrap/dist/css/bootstrap.min.css";

// export default class Root extends Component {
//   render() {
//     const { store, history } = this.props;
//     console.log(store);
//     return (
//       <Provider store={store}>
//         <PersistGate persistor={persiststore} loading={null}>
//           <ConnectedRouter history={history}>
//             <App />
//           </ConnectedRouter>
//         </PersistGate>
//       </Provider>
//     );
//   }
// }

// Root.propTypes = {
//   store: PropTypes.object.isRequired,
//   history: PropTypes.object.isRequired
// };

import React, { Component } from "react";
import PropTypes from "prop-types";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";

export default class Root extends Component {
  render() {
    const { store, history } = this.props;

    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
