import {
  getCardsByTimeIntervalAndProductId,
  getResellerCreditDebit,
  getCardsByProductAndNumber,
  getResellerCards,
  getBankCreditsReportApi,
  getRazorPayCreditsReportApi,
  getBalanceAdjusmentReportApi,
  getLoadMoneyReportByNumber
} from "../apis/reports";

export const REQUEST_REPORTS_ONE = "REQUEST_REPORTS_ONE";
export const REQUEST_REPORTS_ONE_FAILED = "REQUEST_REPORTS_ONE_FAILED";
export const REQUEST_REPORTS_ONE_SUCCESS = "REQUEST_REPORTS_ONE_SUCCESS";

export const REQUEST_REPORTS_TWO = "REQUEST_REPORTS_TWO";
export const REQUEST_REPORTS_TWO_FAILED = "REQUEST_REPORTS_TWO_FAILED";
export const REQUEST_REPORTS_TWO_SUCCESS = "REQUEST_REPORTS_TWO_SUCCESS";

export const REQUEST_REPORTS_THREE = "REQUEST_REPORTS_THREE";
export const REQUEST_REPORTS_THREE_FAILED = "REQUEST_REPORTS_THREE_FAILED";
export const REQUEST_REPORTS_THREE_SUCCESS = "REQUEST_REPORTS_THREE_SUCCESS";

export const REQUEST_REPORTS_RESELLER_CARDS = "REQUEST_REPORTS_RESELLER_CARDS";
export const REQUEST_REPORTS_RESELLER_CARDS_FAILED = "REQUEST_REPORTS_RESELLER_CARDS_FAILED";
export const REQUEST_REPORTS_RESELLER_CARDS_SUCCESS = "REQUEST_REPORTS_RESELLER_CARDS_SUCCESS";

import { ADD_FLASH_MESSAGE, DELETE_FLASH_MESSAGE } from "./flashMsgActions";

// example of a thunk using the redux-thunk middleware
export const getCardsListReport = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_REPORTS_ONE });
  return getCardsByTimeIntervalAndProductId(data)
    .then((result) => {
      // cards.reverse();
      dispatch({
        type: REQUEST_REPORTS_ONE_SUCCESS,
        reports: null,
      });

      return result;
    })
    .catch(() => {});
};

export const getResellerCreditDebitStatus = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_REPORTS_TWO });
  return getResellerCreditDebit(data)
    .then((result) => {
      // cards.reverse();
      dispatch({
        type: REQUEST_REPORTS_TWO_SUCCESS,
        reports: null,
      });
      return result;
    })
    .catch(() => {});
};

export const getResellerCardsList = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_REPORTS_RESELLER_CARDS });
  return getResellerCards(data)
    .then((result) => {
      // cards.reverse();
      dispatch({
        type: REQUEST_REPORTS_RESELLER_CARDS_SUCCESS,
        reports: null,
      });
      return result;
    })
    .catch(() => {});
};

export const getCardsListByProductAndNumber = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_REPORTS_THREE });
  return getCardsByProductAndNumber(data)
    .then((result) => {
      // cards.reverse();
      dispatch({
        type: REQUEST_REPORTS_THREE_SUCCESS,
        reports: null,
      });
      return result;
    })
    .catch(() => {});
};

export const getLoadMoneyReport = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_REPORTS_THREE });
  return getLoadMoneyReportByNumber(data)
    .then((result) => {
      // cards.reverse();
      dispatch({
        type: REQUEST_REPORTS_THREE_SUCCESS,
        reports: null,
      });
      return result;
    })
    .catch(() => {});
};



export const getCreditsReport = (data) => async (dispatch) => {

  dispatch({ type: REQUEST_REPORTS_THREE });
  console.log(data)
  if(data.transferType == 'BANK') {
    return getBankCreditsReportApi(data)
      .then((result) => {
        // cards.reverse();
        dispatch({
          type: REQUEST_REPORTS_THREE_SUCCESS,
          reports: null,
        });
        return result;
      })
      .catch(() => {});
  } else if(data.transferType == 'BALANCE_ADJUSTMENT') {
     return getBalanceAdjusmentReportApi(data)
      .then((result) => {
        // cards.reverse();
        dispatch({
          type: REQUEST_REPORTS_THREE_SUCCESS,
          reports: null,
        });
        return result;
      })
      .catch(() => {});
  }
  else {
    return getRazorPayreditsReportApi(data)
      .then((result) => {
        // cards.reverse();
        dispatch({
          type: REQUEST_REPORTS_THREE_SUCCESS,
          reports: null,
        });
        return result;
      })
      .catch(() => {});

  }
  
};
