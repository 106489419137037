/* eslint require-atomic-updates: off */
import axios from "axios";
import { getHostUrl } from "../utils/env";
import { isNull } from "lodash";

const HOST_URL = getHostUrl();

export async function getRefId(data) {
  return new Promise((res, rej) =>
    axios
      .get(`${HOST_URL}/payments/get-reference-id/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${data.accessCode}`,
        },
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function pay(formData) {
  return new Promise((res, rej) =>
    axios
      .post(`${HOST_URL}/payments/pay/`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${formData.accessCode}`,
        },
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

// approve amount
export function approveAmountAPI(data, lastEvaluatedKey){
  let url = `${HOST_URL}/payments/getwaitingpayments?page=1&size=10`
  if (!isNull(lastEvaluatedKey)) {
    let lastEvaluatedKeyString = encodeURI(lastEvaluatedKey);
    url += `&LastEvaluatedKey=${lastEvaluatedKeyString}`;
  } 
  return new Promise((res, rej) =>
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${data.accessCode}`,
        },
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

// update approvalstatus api
export function updateApprovalStatusAPI(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/payments/updateapprovalstatus`,
        {
          paymentId: data.paymentId,
          approvalStatus: data.approvalStatus,
          phonenumber: data.phonenumber,
          transactionType: data.transactionType,
          amount: data.amount,
          cardId: data.cardId,
          productId: data.productId,
          transactionId: data.transactionId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.accessCode}`,
          },
        }
      )
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

// payment links api

export function getPaymentLinksAPI(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/payment/link/admin`,
        {
          lastEvaluatedKey: data.lastEvaluatedKey,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(error);
      })
  );
}
