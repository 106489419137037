import { combineReducers } from "redux";
import cards from "./cardsReducer";
import user from "./userReducer";
import phoneNumbers from "./phonenumbersReducer";
import resellerActivity from "./resellerActivity";
import flashMsg from "./flashMsgReducer";
import resellerCardsBalances from "./resellerCardsBalances";
import resellerDebit from "./resellerDebit";
import paymentApprovals from "./paymentApprovals";
import createResellerCardsReducer from "./createResellerCardsReducer";
import paymentLinks from "./paymentLinksReducer";
import allUserActivity from "./allUserActivity";
import { connectRouter } from "connected-react-router";
import usersReducer from "./usersReducer";

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    user,
    cards,
    // phoneNumbers,
    resellerActivity,
    resellerCardsBalances,
    resellerDebit,
    paymentApprovals,
    createResellerCardsReducer,
    allUserActivity,
    flashMsg,
    paymentLinks,
    usersReducer
  });

export default rootReducer;
