import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Button,
  Alert,
} from "react-bootstrap";
import _ from "lodash";

import * as actions from "../../actions/loginActions";
import LoginForm from "../LoginForm";
import { EjNavbar } from "../EjNavbar";
import { indianPhoneRegexValidation } from "../../utils/regx";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: "",
      otp: null,
      otpRequested: false,
      error: false,
      errorMessage: null,
      adminPhones: [
        "9897691822",
        "9818682993",
        "9701800711",
        "9521225772"
      ],
    };
  }

  UNSAFE_componentWillMount() {
    if(_.get(this.props, "user.loading")) {
      this.props.actions.logout()
    }
    if (_.get(this.props, "user.accessCode")) {
      //
      this.props.history.push("/");
    }
  }

  componentDidUpdate() {
    if (_.get(this.props, "user.accessCode")) {
      //
      this.props.history.push("/");
    }
  }

  getOtp = async (e) => {
    e.preventDefault();
    // const validation = /[23456789][0-9]{9}/;
    if (
      this.state.phoneNumber &&
      indianPhoneRegexValidation.test(this.state.phoneNumber) &&
      this.state.adminPhones.includes(this.state.phoneNumber)
    ) {
      await this.props.actions.requestUserOtp(this.state.phoneNumber);
      if (this.props.user.otpStatus === "REQUEST_OTP_FAILED") {
        this.setState({ otpRequested: false });
      } else {
        this.setState({ otpRequested: true });
      }
      //   this.setState({ otpRequested: true });
    } else {
      this.setState({
        otpRequested: false,
        error: true,
        errorMessage: "Please enter valid mobile number ",
      });
      setTimeout(() => {
        this.setState({
          error: false,
        });
      }, 4000);
    }
  };

  verifyOtp = (event) => {
    event.preventDefault();
    if (!!this.state.phoneNumber && !!this.state.otp) {
      if (this.state.otp.length != 6) {
        this.setState({ error: true, errorMessage: "OTP has to 6 character" });
        setTimeout(() => {
          this.setState({ error: false, errorMessage: "" });
        }, 4000);
        return;
      }

      var reg = new RegExp("^[0-9]+$");
      if (!reg.test(this.state.otp)) {
        this.setState({
          error: true,
          errorMessage: "OTP can have only numbers",
        });
        setTimeout(() => {
          this.setState({ error: false, errorMessage: "" });
        }, 4000);
        return;
      }

      this.props.actions.verifyUserOtp(this.state.phoneNumber, this.state.otp);
    } else {
      this.setState({
        error: true,
        errorMessage: "Please enter both Phone Number and OTP ",
      });
      setTimeout(() => {
        this.setState({ error: false, errorMessage: "" });
      }, 4000);
    }
  };

  onChange = (key, value) => {
    this.setState({ [key]: value });
  };

  resetPhoneNumber = () => {
    this.setState({
      otpRequested: false,
      error: false,
      errorMessage: null,
    });
  };

  render() {
    const { loading } = this.props.user;
    return (
      <React.Fragment>
        {/* <EjNavbar {...this.props} /> */}
        <Container>
          <Row>
            <Col sm={{ span: 4, offset: 4 }} style={{ marginTop: 100 }}>
              {this.state.error && (
                <Alert variant={"danger"}>{this.state.errorMessage}</Alert>
              )}
              {this.props.user.otpStatus == "VERIFY_OTP_FAILED" && (
                <Alert variant={"danger"}>{this.props.user.error}</Alert>
              )}
              {this.props.user.otpStatus == "REQUEST_OTP_FAILED" && (
                <Alert variant={"danger"}>{this.props.user.error}</Alert>
              )}
              <LoginForm
                onRequestOtp={this.getOtp}
                onChange={this.onChange}
                phoneNumber={this.state.phoneNumber}
                otpRequested={this.state.otpRequested}
                onVerifyOtp={this.verifyOtp}
                loading={loading}
                resetPhoneNumber={this.resetPhoneNumber}
              />
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

LoginPage.propTypes = {
  user: PropTypes.any,
  history: PropTypes.any,
  actions: PropTypes.any,
  loginActions: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
