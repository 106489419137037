import React from "react";
import { connect } from "react-redux";
import { ListGroup, Button, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { CopyToClipboard } from "react-copy-to-clipboard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStream } from "@fortawesome/free-solid-svg-icons";
import { LIMITED_ACCESSS } from '../../utils/env';

class ResellerCard extends React.Component {
  render() {
    return (
      <>
        <Card className="resellerCard">
        {this.props.card.productId.toString() === "9142" ? (
            <img
              src="https://static.echojoy.in/logos/amazon.png"
              class="rounded"
            />
          ) : null 
        }
        {this.props.card.productId.toString() === "9260" ? (
            <img
              src="https://static.echojoy.in/logos/amazonsv.jpg"
              class="rounded"
            />
          ) : null 
        }
          {(this.props.card.productId.toString() != "9142" && this.props.card.productId.toString() != "9260") ? (
            
            <img
              src="https://static.echojoy.in/logos/flipkart.png"
              class="rounded"
            />
          ) : null}
          <Card.Body>
            <Card.Text style={{ fontWeight: "600" }}>
              <span style={{ marginLeft: "10px" }}>
                {this.props.card.pin_or_url}
              </span>
              <span style={{ marginLeft: "10px", fontSize: 18 }}>
                {this.props.card.phonenumber}
              </span>
              <span style={{ float: "left", fontSize: 18, marginRight: 20, color: '#b3acac', letterSpacing: '1px' }}>{this.props.card.cardId}</span>
              <span style={{ float: "left", fontSize: 18 }}>
                &#8377;{this.props.card.card_balance && parseFloat(this.props.card.card_balance).toFixed(2)}
              </span>
            </Card.Text>
          </Card.Body>
          <hr />
          {
              !LIMITED_ACCESSS.includes(this.props.user.phoneNumber) &&
              <Card.Link href="#" className="addMoney-btn">
                <Link
                  to={`${this.props.card.cardId}`}
                >
                  &#8377; Add Money
                </Link>
              </Card.Link>
          }    
          <Card.Link href="#" className="addMoney-btn">
            <Link
              to={`resellercardsbalances/reselleractivity/${this.props.card.phonenumber}/${this.props.card.productId}`}
            >
              <FontAwesomeIcon icon={faStream} size="1x" style={{ marginRight: 5 }}/> Activity
            </Link>
          </Card.Link>
        </Card>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    cards: state.cards,
    phoneNumbers: state.phoneNumbers,
    router: state.router
    // loading: state.phoneNumbers.loading
  };
};

export default connect(mapStateToProps, null)(ResellerCard);
