import { getUserCards, addMoneyToCardAPI } from "../apis/cards";
import * as resellerActions from "./resellerActivityActions";

export const REQUEST_CARDS = "REQUEST_CARDS";
export const REQUEST_CARDS_FAILED = "REQUEST_CARDS_FAILED";
export const REQUEST_CARDS_SUCCESS = "REQUEST_CARDS_SUCCESS";

export const ADD_MONEY_TO_CARD = "ADD_MONEY_TO_CARD";
export const ADD_MONEY_TO_CARD_SUCCESS = "ADD_MONEY_TO_CARD_SUCCESS";
export const ADD_MONEY_TO_CARD_FAIL = "ADD_MONEY_TO_CARD_FAIL";
export const RESET_FORM_DATA = "RESET_FORM_DATA";

import { ADD_FLASH_MESSAGE, DELETE_FLASH_MESSAGE } from "./flashMsgActions";

// example of a thunk using the redux-thunk middleware
export const getCards = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_CARDS });
  dispatch({ type: resellerActions.REQUEST_RESELLER_DEBITS_RESET });
  return getUserCards(data)
    .then((result) => {
      const cards = _.orderBy(result.data.data, "updatedAt");
      // cards.reverse();
      dispatch({
        type: REQUEST_CARDS_SUCCESS,
        cards: cards,
        totalCount: result.data.totalCount,
        page: result.data.page,
        size: result.data.size,
      });
      if (result.data.data.length === 0) {
        dispatch({ type: REQUEST_CARDS_FAILED });
        dispatch({
          type: ADD_FLASH_MESSAGE,
          variant: "success",
          flashMsg: `There are no cards for ${data.number} number`,
        });

        setTimeout(() => {
          dispatch({ type: DELETE_FLASH_MESSAGE });
        }, 3000);
      }
    })
    .catch((error) => {
      console.log(error.response);
      if (error.response.status === 500) {
        dispatch({ type: REQUEST_CARDS_FAILED });
        dispatch({
          type: ADD_FLASH_MESSAGE,
          variant: "danger",
          flashMsg: `invalid phone number ${data.number}`,
        });
      } else {
        dispatch({
          type: REQUEST_CARDS_FAILED,
        });
        dispatch({
          type: ADD_FLASH_MESSAGE,
          variant: "danger",
          flashMsg: `Something went wrong`,
        });
      }

      setTimeout(() => {
        dispatch({ type: DELETE_FLASH_MESSAGE });
      }, 3000);
    });
};

export const addMoneyToCard = (data) => async (dispatch) => {
  dispatch({ type: ADD_MONEY_TO_CARD });
  return addMoneyToCardAPI(data)
    .then((result) => {
      dispatch({ type: ADD_MONEY_TO_CARD_SUCCESS, cards: result });
    })
    .catch(() => {
      dispatch({ type: ADD_MONEY_TO_CARD_FAIL });
    });
};

export const resetFormData = (data) => async (dispatch) => {
  dispatch({ type: RESET_FORM_DATA });
};
