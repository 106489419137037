import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Container, Row, Col, Form, Tabs, Tab } from "react-bootstrap";
import axios from "axios";
import CardsIssuedList from "./Reports/CardsIssuedList";
import ResellerCreditDebit from "./Reports/ResellerCreditDebit";
import CardsListByProductAndNumber from "./Reports/CardsListByProductAndNumber";
import ResellerCardsList from "./Reports/ResellerCardsList";
import CreditsList from "./Reports/CreditsList";
import LoadMoney from "./Reports/LoadMoney";

import * as Yup from "yup";

const ValidPhoneSchema = Yup.object().shape({});

class Reports extends React.Component {
  render() {
    return (
      <>
        <Container>
          <Row>
            <Col sm={{ span: 8, offset: 2 }}>
              <h5 className="element-header">Reports</h5>
              <Tabs defaultActiveKey="report-1" id="uncontrolled-tab-example">
                <Tab eventKey="report-1" title="Cards Issued">
                  <br/>
                  <CardsIssuedList />
                </Tab>
                {/* <Tab eventKey="report-2" title="Reseller Credit/Debit">
                                  <br/>
                                  <ResellerCreditDebit />
                                </Tab>
                                <Tab eventKey="report-3" title="Reseller Cards List">
                                  <br/>
                                  <ResellerCardsList />
                                </Tab>*/}
                                <Tab eventKey="report-4" title="By PhoneNumber">
                                  <br/>
                                  <CardsListByProductAndNumber />
                                </Tab>
                                <Tab eventKey="report-5" title="Credits">
                                  <br/>
                                  <CreditsList />
                                </Tab>
                                <Tab eventKey="report-6" title="Load Money">
                                  <br/>
                                  <LoadMoney />
                                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, null)(Reports);
