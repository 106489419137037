import axios from "axios";
import * as _ from "lodash";
import { getHostUrl } from "../utils/env";

const HOST_URL = getHostUrl();

export function getUserCards(data) {
  return new Promise((res, rej) =>
    axios
      .get(
        `${HOST_URL}/resellers/cards/${data.number}?page=${data.pageNumber}&size=10`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(error);
      })
  );
}

export function addMoneyToCardAPI(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/order/ej/addmoneytoresellercard`,
        {
          amount: data.amount,
          transactionType: data.transactionType,
          brandType: data.brandType,
          transactionId: data.transactionId,
          notes: data.notes,
          adjustmentAmount: data.adjustmentAmount,
          ref_no: data.ref_no,
          cardId: data.cardId,
          product_id: data.product_id,
          phonenumber: data.phonenumber,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.accessCode}`,
          },
        }
      )
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        // alert(JSON.stringify(error))
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

// export function getResellerCardsBalancesAPI(data) {
//   return new Promise((res, rej) =>
//     axios
//       .get(
//         "https://dev.echojoy.in/v1/resellers/getallresellercardbalance?page=2&size=10",
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `JWT ${data.accessCode}`
//           }
//         }
//       )
//       .then(response => {
//         res(cards);
//       })
//       .catch(error => {
//         // alert(JSON.stringify(error))
//         rej(new Error(error));
//       })
//   );
// }
