import React from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";

const LoginForm = (props) => {
  return (
    <React.Fragment>
      {!props.otpRequested ? (
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Phonenumber"
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (event.target.value === "" || re.test(event.target.value)) {
                  props.onChange("phoneNumber", e.target.value);
                }
              }}
            />
            <Form.Text className="text-muted">
              Please enter 10 digit indian number
            </Form.Text>
          </Form.Group>
          <Button
            variant="primary"
            block
            type="submit"
            disabled={props.loading}
            onClick={(e) => props.onRequestOtp(e)}
          >
            {props.loading === true ? (
              <span style={{ marginRight: "10px" }}>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
            ) : null}
            Get OTP
          </Button>
        </Form>
      ) : (
        <Form onSubmit={(e) => props.onVerifyOtp(e)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              defaultValue={props.phoneNumber}
              type="text"
              placeholder="Enter Phonenumber"
            />
            <Form.Text className="text-muted">
              Please enter 10 digit indian number
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>OTP</Form.Label>

            <Form.Control
              type="text"
              placeholder="Enter OTP"
              onChange={(e) => {
                props.onChange("otp", e.target.value);
              }}
            />
            <Form.Text className="text-muted">Enter your 6 digit OTP</Form.Text>
          </Form.Group>

          <Button
            variant="primary"
            block
            type="submit"
            disabled={props.loading}
          >
            {props.loading === true ? (
              <span style={{ marginRight: "10px" }}>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </span>
            ) : null}
            Verify OTP
          </Button>
          <p
            style={{
              textAlign: "center",
              marginTop: "20px",
              fontWeight: "600",
              cursor: "pointer",
            }}
            onClick={(e) => props.resetPhoneNumber(e)}
          >
            Reset PhoneNumber
          </p>
        </Form>
      )}
    </React.Fragment>
  );
};

LoginForm.propTypes = {
  onRequestOtp: PropTypes.func.isRequired,
  onVerifyOtp: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  otpRequested: PropTypes.bool.isRequired,
  phoneNumber: PropTypes.oneOf([PropTypes.null, PropTypes.string]),
};

export default LoginForm;
