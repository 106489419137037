import React from "react";
import { Container, Row, Col, Button, Alert, Spinner } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { indianPhoneRegexValidation } from "../../utils/regx";
import { createResellerCards } from "../../actions/createResellerCardsActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

const ValidPhoneSchema = Yup.object().shape({
  phone: Yup.string().matches(
    indianPhoneRegexValidation,
    "Phone number is not valid"
  ),
});

class CreateResellerCards extends React.Component {
  render() {
    return (
      <>
        <Container>
          <Row>
            <Col sm={{ span: 6, offset: 3 }} style={{ marginTop: 30 }}>
              {this.props.flashMsg.variant === "danger" ? (
                <Alert variant={this.props.flashMsg.variant}>
                  {this.props.createResellerCardsReducer.error}
                  <span style={{ float: "right" }} onClick={this.closeAlert}>
                    <FontAwesomeIcon icon={faTimes} size="1x" />
                  </span>
                </Alert>
              ) : null}

              {this.props.flashMsg.variant === "success" ? (
                <Alert variant={this.props.flashMsg.variant}>
                  {this.props.createResellerCardsReducer.successMsg}
                  <span style={{ float: "right" }} onClick={this.closeAlert}>
                    <FontAwesomeIcon icon={faTimes} size="1x" />
                  </span>
                </Alert>
              ) : null}

              <Formik
                initialValues={{ phone: "", isNewUser: false }}
                validationSchema={ValidPhoneSchema}
                onSubmit={async (value, { resetForm }) => {
                  await this.props.createResellerCards({
                    accessCode: this.props.user.accessCode,
                    phonenumber: value.phone,
                    isNewUser: value.isNewUser,
                  });
                  await resetForm();
                }}
              >
                {({ touched, errors, isSubmitting }) => (
                  <Form>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <Field
                        type="text"
                        name="phone"
                        placeholder="Enter Valid PhoneNumber"
                        className={`form-control ${
                          touched.phone && errors.phone ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="phone"
                        className="invalid-feedback"
                      />
                    </div>

                    <div className="form-group form-check">
                      <Field
                        type="checkbox"
                        name="isNewUser"
                        className="form-check-input"
                      />
                      <label class="form-check-label" for="exampleCheck1">
                        New Reseller
                      </label>
                    </div>

                    <button type="submit" className="btn btn-primary btn-block">
                      {this.props.createResellerCardsReducer.loading ===
                      true ? (
                        <span style={{ marginRight: "10px" }}>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      ) : null}
                      Generate Reseller Cards
                    </button>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

// export default CreateResellerCards;

const mapStateToProps = (state) => {
  return {
    user: state.user,
    flashMsg: state.flashMsg,
    createResellerCardsReducer: state.createResellerCardsReducer,
    // loading: state.phoneNumbers.loading
  };
};

export default connect(mapStateToProps, { createResellerCards })(
  CreateResellerCards
);
