import { createResellerCardsAPI } from "../apis/resellers";

export const CREATE_RESELLER_CARDS = "CREATE_RESELLER_CARDS";
export const CREATE_RESELLER_CARDS_FAILED = "CREATE_RESELLER_CARDS_FAILED";
export const CREATE_RESELLER_CARDS_SUCCESS = "CREATE_RESELLER_CARDS_SUCCESS";

import { ADD_FLASH_MESSAGE, DELETE_FLASH_MESSAGE } from "./flashMsgActions";

export const createResellerCards = (data) => async (dispatch) => {
  dispatch({ type: CREATE_RESELLER_CARDS });
  return createResellerCardsAPI(data)
    .then((result) => {
      dispatch({ type: CREATE_RESELLER_CARDS_SUCCESS, data: result });
      dispatch({
        type: ADD_FLASH_MESSAGE,
        variant: "success",
        flashMsg: "Successfully created cards for reseller.",
      });

      setTimeout(() => {
        dispatch({ type: DELETE_FLASH_MESSAGE });
      }, 3000);
    })
    .catch((err) => {
      dispatch({ type: CREATE_RESELLER_CARDS_FAILED, data: err });
      if (err.response.data.status === 500) {
        dispatch({
          type: ADD_FLASH_MESSAGE,
          variant: "danger",
          flashMsg: err.response.data.message,
        });
      }
      dispatch({
        type: ADD_FLASH_MESSAGE,
        variant: "danger",
        flashMsg: "Something went wrong.",
      });

      setTimeout(() => {
        dispatch({ type: DELETE_FLASH_MESSAGE });
      }, 3000);
    });
};
