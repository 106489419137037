import * as actionTypes from "../actions/resellerActivityActions";
import * as actionType from "../actions/loginActions";

const initialState = {
  loading: false,
  reseller_debits: [],
  error: null,
  lastEvaluatedKey: null,
  phonenumber: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    // phonenumber list
    case actionTypes.REQUEST_RESELLER_DEBITS:
      return {
        ...state,
        loading: true
      };
    case actionTypes.REQUEST_RESELLER_DEBITS_RESET:
      return initialState
    case actionTypes.REQUEST_RESELLER_DEBITS_SUCCESS:
      console.log(action)
      return {
        ...state,
        loading: false,
        reseller_debits: [...state.reseller_debits, ...action.resellerDebits],
        lastEvaluatedKey: action.lastEvaluatedKey
      };

    case actionTypes.REQUEST_RESELLER_DEBITS_FAILED:
      return {
        ...state,
        loading: false,
        reseller_debits: []
      };

    case actionType.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}
