/* eslint require-atomic-updates: off */

import {
  pay,
  getRefId,
  approveAmountAPI,
  updateApprovalStatusAPI,
  getPaymentLinksAPI
} from "../apis/payments";

export const REQUEST_PAYMENT = "REQUEST_PAYMENT";
export const REQUEST_PAYMENT_FAILED = "REQUEST_PAYMENT_FAILED";
export const REQUEST_PAYMENT_SUCCESS = "REQUEST_PAYMENT_SUCCESS";
export const PAYMENT_ID_REQUEST_FAILED = "PAYMENT_ID_REQUEST_FAILED";

export const REQUEST_APPROVAL_PAYMENTS = "REQUEST_APPROVAL_PAYMENTS";
export const REQUEST_APPROVAL_PAYMENTS_SUCCESS =
  "REQUEST_APPROVAL_PAYMENTS_SUCCESS";
export const REQUEST_APPROVAL_PAYMENTS_FAILED =
  "REQUEST_APPROVAL_PAYMENTS_FAILED";

export const REQUEST_UPDATE_APPROVAL_STATUS = "REQUEST_UPDATE_APPROVAL_STATUS";
export const REQUEST_UPDATE_APPROVAL_STATUS_SUCCESS =
  "REQUEST_UPDATE_APPROVAL_STATUS_SUCCESS";
export const REQUEST_UPDATE_APPROVAL_STATUS_FAILED =
  "REQUEST_UPDATE_APPROVAL_STATUS_FAILED";

export const REQUEST_PAYMENT_LINKS = "REQUEST_PAYMENT_LINKS";
export const REQUEST_PAYMENT_LINKS_SUCCESS =
  "REQUEST_PAYMENT_LINKS_SUCCESS";
export const REQUEST_PAYMENT_LINKS_FAILED =
  "REQUEST_PAYMENT_LINKS_FAILED";

export const CLOSE_ALERT_UPDATE_APPROVAL = "CLOSE_ALERT_UPDATE_APPROVAL";

export const redeemFromCard = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_PAYMENT });

  return pay(data)
    .then((result) => {
      dispatch({ type: REQUEST_PAYMENT_SUCCESS });
      return result;
    })
    .catch((error) => {
      dispatch({ type: REQUEST_PAYMENT_FAILED });
      return error;
    });
};

export const fetchPaymentId = (data) => async (dispatch) => {
  return getRefId(data)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      dispatch({ type: PAYMENT_ID_REQUEST_FAILED });
      return error;
    });
};

  export const getWaitingApprovalPayments = (data) => async (dispatch) => {
    dispatch({ type: REQUEST_APPROVAL_PAYMENTS });
  
    let allPayments = [];
    let LastEvaluatedKey = null;
    let totalCount = 0; 
    let pageCount = 10;
  
    let result = {}
    try {
      do{
        result = JSON.parse(await approveAmountAPI(data, LastEvaluatedKey));
        allPayments.data = [...allPayments, ...result.data];
        LastEvaluatedKey = result.customizedData && result.customizedData.LastEvaluatedKey || null;
        totalCount += result.customizedData && parseInt(result.customizedData.count) || 0;
      } while (totalCount < pageCount && LastEvaluatedKey);
  
      dispatch({ type: REQUEST_APPROVAL_PAYMENTS_SUCCESS, data: allPayments });
      return allPayments; 
    } catch (error) { 
      dispatch({ type: REQUEST_APPROVAL_PAYMENTS_FAILED, error: error });
      return error;
    }
  
  };

export const updateApprovalStatus = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_UPDATE_APPROVAL_STATUS, data: data });

  return updateApprovalStatusAPI(data)
    .then((result) => {
      dispatch({ type: REQUEST_UPDATE_APPROVAL_STATUS_SUCCESS, data: data });
      return result;
    })
    .catch((error) => {
      dispatch({
        type: REQUEST_UPDATE_APPROVAL_STATUS_FAILED,
        data: data,
        error: error,
      });
      return error;
    });
};

export const getPaymentLinks = (data) => async (dispatch) => {
  dispatch({ type: REQUEST_PAYMENT_LINKS, data: data });

  return getPaymentLinksAPI(data)
    .then((result) => {
      dispatch({ 
        type: REQUEST_PAYMENT_LINKS_SUCCESS, 
        items: result.data.data.items,
        lastEvaluatedKey: result.data.data.lastEvaluatedKey,
      });
      return result;
    })
    .catch((error) => {
      dispatch({
        type: REQUEST_PAYMENT_LINKS_FAILED,
        data: data,
        error: error,
      });
      return error;
    });
};

export const closeAlert = () => {
  return { type: CLOSE_ALERT_UPDATE_APPROVAL };
};
