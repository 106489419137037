import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ResellerCard from "./ResellerCard";
import LoadResellerMoney from "./LoadResellerMoney";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Button,
  Alert,
  Spinner,
  Pagination,
} from "react-bootstrap";
import _ from "lodash";
import { Route, Redirect, Switch, Link } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import { EjNavbar } from "../EjNavbar";
import * as actions from "../../actions/cardActions";
import * as loginActions from "../../actions/loginActions";
import * as paymentActions from "../../actions/paymentActions";
import * as orderActions from "../../actions/orderActions";
import * as phoneActions from "../../actions/phoneActions";
import "../../styles/nav.scss";
import { indianPhoneRegexValidation } from "../../utils/regx";

const active = {
  backgroundColor: "blue",
};

const ValidPhoneSchema = Yup.object().shape({
  phone: Yup.string().matches(
    indianPhoneRegexValidation,
    "Phone number is not valid"
  ),
});

class GetCardsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //   UNSAFE_componentWillMount() {
  //     if (!_.get(this.props, "user.accessCode")) {
  //       //
  //       this.props.history.push("/login");
  //     }
  //   }

  componentDidMount() {
    // Get the cards and details for this user
  }

  logout = async () => {
    this.props.loginActions.logout().then(() => {
      this.props.history.push("/login");
    });
  };

  handleChangePhone = (event) => {
    this.setState({ phoneValue: event.target.value });
  };

  getResellerCards = (pageNumber) => {
    this.props.actions.getCards({
      access_token: this.props.user.accessCode,
      number: value.phone,
      pageNumber: pageNumber,
    });
  };

  render() {
    const { loading, cards } = this.props.cards;

    return (
      <>
        <Container>
          <Row>
            <Col sm={{ span: 8, offset: 2 }} style={{ marginTop: 30 }}>
              <h5 className="element-header">Get Cards</h5>
              {this.props.flashMsg.flashMsg !== "" &&
                this.props.flashMsg.variant !== "" && (
                  <Alert variant={this.props.flashMsg.variant}>
                    {this.props.flashMsg.flashMsg}
                  </Alert>
                )}
              <Formik
                initialValues={{ phone: "" }}
                validationSchema={ValidPhoneSchema}
                onSubmit={async (value, { resetForm }) => {
                  await this.props.actions.getCards({
                    access_token: this.props.user.accessCode,
                    number: value.phone,
                    pageNumber: 1,
                  });

                  await resetForm();
                }}
              >
                {({ touched, errors, isSubmitting, dirty }) => (
                  <Form noValidate>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <Field
                        type="text"
                        name="phone"
                        placeholder="Enter Valid PhoneNumber"
                        className={`form-control ${
                          touched.phone && errors.phone ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="phone"
                        className="invalid-feedback"
                      />
                    </div>
                    <Button
                      type="submit"
                      className="btn btn-primary btn-block"
                      disabled={isSubmitting || !dirty}
                    >
                      {loading && (
                        <Spinner
                          animation="grow"
                          role="status"
                          size="sm"
                          style={{ marginRight: 10 }}
                        >
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      )}
                      <span>Submit</span>
                    </Button>
                  </Form>
                )}
              </Formik>
              {loading === false && (
                <>
                  {cards.map((card) => {
                    return <ResellerCard card={card} />;
                  })}
                </>
              )}
              <br />
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

GetCardsPage.propTypes = {
  history: PropTypes.object,
  user: PropTypes.object,
  actions: PropTypes.object,
  paymentActions: PropTypes.object,
  loginActions: PropTypes.object,
  orderActions: PropTypes.object,
  // cards: PropTypes.array
  //   phoneNumbers: PropTypes.arrayOf(
  //     PropTypes.shape({
  //       phone_number: PropTypes.string,
  //       id: PropTypes.number
  //     })
  //   )
};

function mapStateToProps(state) {
  return {
    user: state.user,
    cards: state.cards,
    flashMsg: state.flashMsg,
    // loading: state.phoneNumbers.loading
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
    paymentActions: bindActionCreators(paymentActions, dispatch),
    orderActions: bindActionCreators(orderActions, dispatch),
    phoneActions: bindActionCreators(phoneActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GetCardsPage);

// <Image src="https://static.echojoy.in/Indiabuys.png" fluid />
