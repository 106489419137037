import React from 'react';
import { Navbar, Nav, Container, Row, Col, Button, Form, Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { EjNavbar } from '../EjNavbar';

class PaymentsPage extends React.Component {

	UNSAFE_componentWillMount() {
		!_.get(this.props, 'user.accessCode') && this.props.history.push('/login');
	}
	
	render() {
		return (
			<>
				<EjNavbar {...this.props} />
				<h2>Coming soon</h2>
			</>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.user
	};
};

export default connect(mapStateToProps, null)(PaymentsPage);
