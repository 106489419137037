import React from "react";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
  Spinner,
  Dropdown
} from "react-bootstrap";
class ResellerCardBalance extends React.Component {
  render() {
    const reseller = this.props.data.find(
      reseller => reseller.phoneNumber === this.props.match.params.phone
    );
    if (reseller) {
      if (reseller.cards.length === 0) {
        return (
          <>
            <h2 style={{ textAlign: "center" }}>
              Reseller: {reseller.phoneNumber}
            </h2>
            <Alert variant="primary" style={{ textAlign: "center" }}>
              Reseller Doesn't habe any card yet
            </Alert>
          </>
        );
      }
      return (
        <>
          <h2 style={{ textAlign: "center" }}>
            Reseller: {reseller.phoneNumber}{" "}
          </h2>
          {reseller.cards.map(card => {
            return (
              <Card
                key={card.cardId}
                style={{
                  backgroundColor: "#34495e",
                  color: "#ecf0f1"
                }}
              >
                <Card.Body>
                  <Card.Text style={{ fontSize: "1rem" }}>
                    PIN:{" "}
                    <span style={{ fontWeight: "600" }}>{card.pin_or_url}</span>
                  </Card.Text>
                  <div>
                    <Row>
                      <Col>
                        <Card.Text style={{ fontSize: "0.8rem" }}>
                          EXPIRES ON:{" "}
                          <span style={{ fontWeight: 600 }}>
                            {card.expiry_date}
                          </span>
                        </Card.Text>
                      </Col>
                      <Col>
                        <Card.Text style={{ fontSize: "0.8rem" }}>
                          CARD BALANCE:{" "}
                          <span style={{ fontWeight: 600 }}>
                            {card.card_balance}
                          </span>
                        </Card.Text>
                      </Col>
                    </Row>
                  </div>
                </Card.Body>
              </Card>
            );
          })}
        </>
      );
    }
  }
}

export default ResellerCardBalance;
