import React from "react";
import axios from "axios";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Button,
  Form,
  Alert,
  Spinner,
  Card
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { EjNavbar } from "../EjNavbar";
import "../../styles/dashboard.scss";
import { throwStatement } from "@babel/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo } from "@fortawesome/free-solid-svg-icons";
import NumberFormat from 'react-number-format';

const HOST_URL = "https://api2.eyantra.com/ej/v2";

class DashboardPage extends React.Component {

  state = {
    amazonBalance: null,
    nonAmazonBalance: null,
    isLoading: true,
    isLoadingNonAmazon: true,
    isLoadingFlipKart: true,
    errors: null,
    isLoadingResellersCardBalance: true,
    isLoadingResellersAmazonCardBalance: false,
    isLoadingNonResellerCardsBalance: false,
    isLoadingRazorpayTransfers: true,
    isLoadingBankTransfers: true,
    isLoadingResellersFlipkartCardBalance: false,
    isLoadingRefunds: true,
    amazonCardsBalance: null,
    flipkartCardsBalance: null,
    nonResellerCardsBalance: null,
    flipkartBalance: null,
    nonResellerCardsCount: 0,
    flipkartCardsCount: 0,
    amazonCardsCount: 0,
    razorPayTransfersTotal: 0,
    razorPayTransfersData: [],
    bankTransfersTotal: 0,
    totalRefunds: 0,
    amazonServerStatus:"",
    flipkartCardsBalance: "",
    bankTransfersData: [],
    refundsData: []
  };

  getAmazonBalance() {
    axios
      .post(
        // `https://api2.echojoy.co.in/qc/v1/checkbalance`,
        `https://api2.eyantra.com/qc/v1/checkbalance`,
        {
          cardnumber: "7014850010000078",
          storeid: 140
        },
        {
          headers: {
            Host: "api.echojoy.in"
          }
        }
      )
      .then(response => {
        this.setState({
          amazonBalance: parseInt(response.data.data.cardbalance).toFixed(2),
          isLoading: false
        })
      .catch((error) => {
        let responseMessage;
        responseMessage = error.response.data.message;
  
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error(responseMessage); // Log the error message for debugging
        }
        rej(new Error(error));
        });
      });
  }
  
  getServerStatus(type) {
    axios
      .get(
        `https://api2.eyantra.com/ej/v2/app/server/${type}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.props.user.accessCode}`
          }
        }
      )
      .then(response => {
        if(type == "FLIPKART") {
          this.setState({
            flipkartServerStatus: response.data.status,
            isLoading: false
          });  
        } else {
          this.setState({
            amazonServerStatus: response.data.status,
            isLoading: false
          });  
        }
      })
      .catch((error) => {
        let responseMessage;
        responseMessage = error.response.data.message;
  
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error(responseMessage); // Log the error message for debugging
        }
        rej(new Error(error));
      });
  }

  setServerStatus(type) {
    let status;
    if(type == "FLIPKART") {
      status = this.state.flipkartServerStatus == "ACTIVE" ? "INACTIVE" : "ACTIVE";
    } else {
      status = this.state.amazonServerStatus == "ACTIVE" ? "INACTIVE" : "ACTIVE";
    }

    axios
      .post(
        `https://api2.eyantra.com/ej/v2/app/server`,
        {
          "set_status": status,
          "server": type
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.props.user.accessCode}`
          }
        }
      )
      .then(response => {
        this.setState({
          serverStatus: response.data.status,
          isLoading: false
        });

        this.getServerStatus(type);
      })
      .catch((error) => {
        let responseMessage;
        responseMessage = error.response.data.message;
  
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error(responseMessage); // Log the error message for debugging
        }
        rej(new Error(error));
    });
  }

  getNonAmazonBalance() {
    axios
      .post(
        // `https://api2.echojoy.co.in/qcnamz/v1/checkbalance`,
        `https://api2.eyantra.com/namz/v1/checkbalance`,
        {
          cardnumber: "2144380020000159",
          storeid: 140
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.props.user.accessCode}`
          }
        }
      )
      .then(response => {
        this.setState({
          nonAmazonBalance: parseInt(response.data.data.cardbalance).toFixed(2),
          isLoadingNonAmazon: false
        });
      })
      .catch((error) => {
        let responseMessage;
        responseMessage = error.response.data.message;
  
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error(responseMessage); // Log the error message for debugging
        }
        rej(new Error(error));  
      });
  }

  getFlipKartBalance() {
    axios
      .post(`https://api2.eyantra.com/flipkart/v1/checkbalance`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${this.props.user.accessCode}`
        }
      })
      .then(response => {
        this.setState({
          flipkartBalance: parseInt(response.data.data.flipKartBalance).toFixed(
            2
          ),
          isLoadingFlipKart: false
        });
      })
      .catch((error) => {
        let responseMessage;
        responseMessage = error.response.data.message;
  
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error(responseMessage); // Log the error message for debugging
        }
        rej(new Error(error));

  
      });
  }

  resellerAmazonCardBalance() {
    axios
      .get(
        `${HOST_URL}/resellers/totalcardsbalance/AMAZON`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.props.user.accessCode}`
          }
        }
      )
      .then(response => {
        console.log("after c")
        this.setState({
          amazonCardsBalance:
            parseFloat(response.data.totalCardsBalance),
          amazonCardsCount:
            parseFloat(response.data.totalCardItemsCount),
          isLoadingResellersAmazonCardBalance: false
        });
      })
      .catch((error) => {
        // Handle the error and set the state accordingly

        let responseMessage;
        responseMessage = error.response.data.message;
  
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error(responseMessage); // Log the error message for debugging
        }
        rej(new Error(error));
        alert('Report will send to email');
        this.setState({
          isLoadingResellersAmazonCardBalance: false
      });

  
      });
  }

  resellerFlipkartCardBalance() {
    axios
      .get(
        `${HOST_URL}/resellers/totalcardsbalance/FLIPKART`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.props.user.accessCode}`
          }
        }
      )
      .then(response => {
        this.setState({
          flipkartCardsBalance:
            parseFloat(response.data.totalCardsBalance),
          flipkartCardsCount:
            parseFloat(response.data.totalCardItemsCount),
          isLoadingResellersFlipkartCardBalance: false
        });
      })
      .catch((error) => {
        let responseMessage;
        responseMessage = error.response.data.message;
  
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error(responseMessage); // Log the error message for debugging
        }
        rej(new Error(error));

  
  
        alert('Report will send to email');
        this.setState({
          isLoadingResellersFlipkartCardBalance: false
        });
      });
  }
 
  nonResellerCardBalance() {
    axios
      .get(
        `${HOST_URL}/cards/totalnamzfkcardsbalance`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.props.user.accessCode}`
          }
        }
      )
      .then(response => {
        this.setState({
          nonResellerCardsBalance:
            parseFloat(response.data.totalCardsBalance),
          nonResellerCardsCount:
            parseFloat(response.data.totalCardItemsCount),
          isLoadingNonResellerCardsBalance: false
        });
      }).catch(function(error) {
        let responseMessage;
        responseMessage = error.response.data.message;
  
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error(responseMessage); // Log the error message for debugging
        }
        rej(new Error(error));
        alert('Report will send to email')

        this.setState({
          isLoadingNonResellerCardsBalance: false
        });
      });
  }

  razorPayTransfers() {
    let todayStart = new Date();
    todayStart.setHours(0,0,0,0);

    const startDate = todayStart 
    const endDate = new Date(Date.now())

    axios
      .post(
        `${HOST_URL}/dashboard/razorPayReport`,
        {
          "fromDate": todayStart,
          "toDate": endDate
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.props.user.accessCode}`
          }
        }
      )
      .then(response => {
        this.setState({
          razorPayTransfersTotal:
            parseFloat(response.data.responseObj.totalAmount),
          razorPayTransfersData: response.data.responseObj.data,
          isLoadingRazorpayTransfers: false
        });
      }).catch((e) => {
        let responseMessage;
          
        responseMessage = e.response.data.message;
  
    
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.e(responseMessage); // Log the error message for debugging
        }
        rej(new Error(error));
        this.setState({
          isLoadingRazorpayTransfers: false
        });
      });
  }
  
  bankTransfers() {
    let todayStart = new Date();
    todayStart.setHours(0,0,0,0);

    const startDate = todayStart 
    const endDate = new Date(Date.now())
    axios
      .post(
        `${HOST_URL}/dashboard/bankTransferReport`,
        {
          "fromDate": todayStart,
          "toDate": endDate
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.props.user.accessCode}`
          }
        }
      )
      .then(response => {
        this.setState({
          bankTransfersTotal:
            parseFloat(response.data.responseObj.totalAmount),
          bankTransfersData: response.data.responseObj.data,
          isLoadingBankTransfers: false
        });
      }).catch((e) => {
        let responseMessage;
          
        responseMessage = e.response.data.message;
  
    
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.e(responseMessage); // Log the error message for debugging
        }
        rej(new Error(error));
        this.setState({
          isLoadingBankTransfers: false
        });
      });;
  }

  getTodayRefunds() {
    axios
      .post(
        `${HOST_URL}/refund/date`,
        {
          "date": new Date().toISOString().split('T')[0]
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${this.props.user.accessCode}`
          }
        }
      )
      .then(response => {
        const data = response.data.data && response.data.data.items;
        const totalRefunds = _.sumBy(data, 'amount')
        this.setState({
          refundsData: data,
          totalRefunds: totalRefunds,
          isLoadingRefunds: false
        });
      }).catch((e) => {
        let responseMessage;
        responseMessage = e.response.data.message;
    
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.e(responseMessage); // Log the error message for debugging
        }
        rej(new Error(error));

        this.setState({
          isLoadingRefunds: false
        });
      });
  }

  componentDidMount() {
    this.getServerStatus("AMAZON");
    this.getServerStatus("FLIPKART");
    this.getAmazonBalance();
    this.getNonAmazonBalance();
    this.getFlipKartBalance();
    // this.resellerFlipkartCardBalance();
    // this.resellerAmazonCardBalance();
    // this.nonResellerCardBalance();
    this.razorPayTransfers();
    this.bankTransfers();
    this.getTodayRefunds();
  }

  render() {
    const {
      amazonBalance,
      nonAmazonBalance,
      isLoading,
      isLoadingNonAmazon,
      isLoadingFlipKart,
      flipkartBalance,
      nonResellerCardsCount,
      flipkartCardsCount,
      amazonCardsCount,
      amazonCardsBalance,
      flipkartCardsBalance,
      nonResellerCardsBalance,
      isLoadingResellersAmazonCardBalance,
      isLoadingResellersFlipkartCardBalance,
      isLoadingResellersCardBalance,
      isLoadingRazorpayTransfers,
      isLoadingBankTransfers,
      isLoadingRefunds,
      totalAllResellersCardBalance,
      isLoadingNonResellerCardsBalance,
      bankTransfersData,
      razorPayTransfersData,
      refundsData,
      bankTransfersTotal,
      totalRefunds,
      razorPayTransfersTotal
    } = this.state;

    return (
      <>
        <Container style={{ marginBottom: 80 }}>
          <Row>
            <Col md={{ span: 12, offset: 0 }}>
              <h5 className="element-header">Server Status</h5>
            </Col>
            <Col md={{ span: 4, offset: 0 }}>
              {/* Amazon */}
               <Card className="cardTile">
                  <Card.Body>
                    <Card.Text style={{ fontWeight: "600" }}>
                      <div className={'name'}>Amazon</div>
                      <div className={'value'}>
                        <div>{this.state.amazonServerStatus}</div>
                        <div>
                          <Button danger onClick={() => this.setServerStatus("AMAZON")}> 
                            {this.state.amazonServerStatus == "ACTIVE" ? "Make InActive" : "Make Active"}
                          </Button>
                        </div>
                      </div>
                    </Card.Text>
                  </Card.Body>
                  {isLoading === true ? (
                      <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                        <Spinner animation="grow" role="status" size="sm" />
                      </div>
                    ) : (
                    <Button
                      href="#"
                      className="refreshBtn"
                      onClick={() => {
                        this.getServerStatus("AMAZON");
                        this.setState({
                          isLoading: true
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faRedo} size="1x" />
                    </Button>
                  )}
                </Card>
              </Col>
              <Col md={{ span: 4, offset: 0 }}>
              {/* Amazon */}
               <Card className="cardTile">
                  <Card.Body>
                    <Card.Text style={{ fontWeight: "600" }}>
                      <div className={'name'}>Flipkart</div>
                      <div className={'value'}>
                        <div>{this.state.flipkartServerStatus}</div>
                        <div>
                          <Button danger onClick={() => this.setServerStatus("FLIPKART")}> 
                            {this.state.flipkartServerStatus == "ACTIVE" ? "Make InActive" : "Make Active"}
                          </Button>
                        </div>
                      </div>
                    </Card.Text>
                  </Card.Body>
                  {isLoading === true ? (
                      <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                        <Spinner animation="grow" role="status" size="sm" />
                      </div>
                    ) : (
                    <Button
                      href="#"
                      className="refreshBtn"
                      onClick={() => {
                        this.getServerStatus("FLIPKART");
                        this.setState({
                          isLoading: true
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faRedo} size="1x" />
                    </Button>
                  )}
                </Card>
              </Col>
            </Row>
            <Row>
            <Col md={{ span: 12, offset: 0 }}>
              <h5 className="element-header">Card Balances</h5>
            </Col>
            <Col md={{ span: 4, offset: 0 }}>
              {/* Amazon */}
               <Card className="cardTile">
                  <Card.Body>
                    <Card.Text style={{ fontWeight: "600" }}>
                      <div className={'name'}>QC-Amazon</div>
                      <div className={'value'}>
                        {/* &#8377; {amazonBalance} */}
                        <NumberFormat 
                          prefix={'₹'}
                          displayType={'text'}
                          thousandsGroupStyle="lakh"
                          value={amazonBalance}  
                          thousandSeparator={true} />
                      </div>
                    </Card.Text>
                  </Card.Body>
                  {isLoading === true ? (
                      <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                        <Spinner animation="grow" role="status" size="sm" />
                      </div>
                    ) : (
                    <Button
                      href="#"
                      className="refreshBtn"
                      onClick={() => {
                        this.getAmazonBalance();
                        this.setState({
                          isLoading: true
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faRedo} size="1x" />
                    </Button>
                  )}
                </Card>
              </Col>
              <Col md={{ span: 4, offset: 0 }}>
                <Card className="cardTile">
                    <Card.Body>
                      <Card.Text style={{ fontWeight: "600" }}>
                        <div className={'name'}>QC-NonAmazon</div>
                        <div className={'value'}>
                          {/* &#8377; {nonAmazonBalance} */}
                          <NumberFormat 
                           prefix={'₹'}
                           displayType={'text'}
                           thousandsGroupStyle="lakh"
                           value= {nonAmazonBalance}
                           thousandSeparator={true} />
                        </div>
                      </Card.Text>
                    </Card.Body>
                    {isLoadingNonAmazon === true ? (
                        <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                          <Spinner animation="grow" role="status" size="sm" />
                        </div>
                      ) : (
                      <Button
                        href="#"
                        className="refreshBtn"
                        onClick={() => {
                          this.getNonAmazonBalance();
                          this.setState({
                            isLoadingNonAmazon: true
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faRedo} size="1x" />
                      </Button>
                    )}
                  </Card>
              </Col>
              <Col md={{ span: 4, offset: 0 }}>
                <Card className="cardTile">
                    <Card.Body>
                      <Card.Text style={{ fontWeight: "600" }}>
                        <div className={'name'}>Flipkart</div>
                        <div className={'value'}>
                          {/* &#8377; {flipkartBalance} */}
                          <NumberFormat 
                           prefix={'₹'}
                           displayType={'text'}
                           thousandsGroupStyle="lakh"
                           value= {flipkartBalance}
                           thousandSeparator={true} />
                        </div>
                      </Card.Text>
                    </Card.Body>
                    {isLoadingFlipKart === true ? (
                        <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                          <Spinner animation="grow" role="status" size="sm" />
                        </div>
                      ) : (
                      <Button
                        href="#"
                        className="refreshBtn"
                        onClick={() => {
                          this.getNonAmazonBalance();
                          this.setState({
                            isLoadingFlipKart: true
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faRedo} size="1x" />
                      </Button>
                    )}
                  </Card>
              </Col>
            </Row>
            <Row>
              <Col md={{ span: 12, offset: 0 }}>
                <h5 className="element-header">Liability</h5>
              </Col>
              <Col md={{ span: 4, offset: 0 }}>
              {/* Total Balance */}
              <Card className="cardTile">
                    <Card.Body>
                      <Card.Text style={{ fontWeight: "600" }}>
                        <div className={'name'}>Amazon Cards ({amazonCardsCount})</div>
                        <div className={'value'}>
                          <NumberFormat 
                           prefix={'₹'}
                           displayType={'text'}
                           thousandsGroupStyle="lakh"
                           value= {amazonCardsBalance && parseFloat(amazonCardsBalance).toFixed(2)}
                           thousandSeparator={true} />

                        </div>
                        <Button
                          type="submit"
                          className="btn btn-primary btn-block"
                          onClick={ () => {this.resellerAmazonCardBalance()}}
                        > 
                          Get
                        </Button>
                      </Card.Text>
                    </Card.Body>
                    {isLoadingResellersAmazonCardBalance === true ? (
                        <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                          <Spinner animation="grow" role="status" size="sm" />
                        </div>
                      ) : (
                      <Button
                        href="#"
                        className="refreshBtn"
                        onClick={() => {
                          this.resellerAmazonCardBalance();
                          this.setState({
                            isLoadingResellersAmazonCardBalance: true
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faRedo} size="1x" />
                      </Button>
                    )}
                  </Card>
                  </Col>
                  <Col md={{ span: 4, offset: 0 }}>
                  {/* Total Balance */}
                  <Card className="cardTile">
                        <Card.Body>
                          <Card.Text style={{ fontWeight: "600" }}>
                            <div className={'name'}>Flipkart Cards({flipkartCardsCount})</div>
                            <div className={'value'}>
                               <NumberFormat 
                                 prefix={'₹'}
                                 displayType={'text'}
                                 thousandsGroupStyle="lakh"
                                 value= {flipkartCardsBalance && parseFloat(flipkartCardsBalance).toFixed(2)}
                                 thousandSeparator={true} />
                            </div> 
                            <Button
                              type="submit"
                              className="btn btn-primary btn-block"
                              onClick={() => {this.resellerFlipkartCardBalance()}}
                            > 
                              Get
                            </Button>
                          </Card.Text>
                        </Card.Body>
                        {isLoadingResellersFlipkartCardBalance === true ? (
                            <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                              <Spinner animation="grow" role="status" size="sm" />
                            </div>
                          ) : (
                          <Button
                            href="#"
                            className="refreshBtn"
                            onClick={() => {
                              this.resellerFlipkartCardBalance();
                              this.setState({
                                isLoadingResellersFlipkartCardBalance: true
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faRedo} size="1x" />
                          </Button>
                        )}
                  </Card>
                </Col>
                <Col md={{ span: 4, offset: 0 }}>
                  {/* Total Balance */}
                  <Card className="cardTile">
                        <Card.Body>
                          <Card.Text style={{ fontWeight: "600" }}>
                            <div className={'name'}>Non Reseller Cards({nonResellerCardsCount})</div>
                            <div className={'value'}>
                              <NumberFormat 
                               prefix={'₹'}
                               displayType={'text'}
                               thousandsGroupStyle="lakh"
                               value= {nonResellerCardsBalance && parseFloat(nonResellerCardsBalance).toFixed(2)}
                               thousandSeparator={true} />
                            </div>
                            <Button
                              type="submit"
                              className="btn btn-primary btn-block"
                              onClick={() => {this.nonResellerCardBalance()}}
                            > 
                              Get
                            </Button>
                          </Card.Text>
                        </Card.Body>
                        {isLoadingNonResellerCardsBalance === true ? (
                            <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                              <Spinner animation="grow" role="status" size="sm" />
                            </div>
                          ) : (
                          <Button
                            href="#"
                            className="refreshBtn"
                            onClick={() => {
                              this.nonResellerCardBalance();
                              this.setState({
                                isLoadingNonResellerCardsBalance: true
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faRedo} size="1x" />
                          </Button>
                        )}
                  </Card>
                </Col>
          </Row>
          <Row>
              <Col md={{ span: 12, offset: 0 }}>
                <h5 className="element-header">Todays KPIs</h5>
              </Col>
              <Col md={{ span: 4, offset: 0 }}>
              {/* Total Balance */}
              <Card className="cardTile">
                    <Card.Body>
                      <Card.Text style={{ fontWeight: "600" }}>
                        <div className={'name'}>RazorPay Transfers ({razorPayTransfersData.length && razorPayTransfersData[0].numTransactions})</div>
                        <div className={'value'}>
                           <NumberFormat 
                               prefix={'₹'}
                               displayType={'text'}
                               thousandsGroupStyle="lakh"
                               value= {razorPayTransfersTotal && razorPayTransfersTotal.toFixed(2)}
                               thousandSeparator={true} />
                        </div>
                      </Card.Text>
                    </Card.Body>
                    {isLoadingRazorpayTransfers === true ? (
                        <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                          <Spinner animation="grow" role="status" size="sm" />
                        </div>
                      ) : (
                      <Button
                        href="#"
                        className="refreshBtn"
                        onClick={() => {
                          this.razorPayTransfers();
                          this.setState({
                            isLoadingRazorpayTransfers: true
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faRedo} size="1x" />
                      </Button>
                    )}
                  </Card>
                  </Col>
                  <Col md={{ span: 4, offset: 0 }}>
                  {/* Total Balance */}
                  <Card className="cardTile">
                        <Card.Body>
                          <Card.Text style={{ fontWeight: "600" }}>
                            <div className={'name'}>Bank Transfers ({bankTransfersData.length && bankTransfersData[0].numTransactions})</div>
                            <div className={'value'}>
                              <NumberFormat 
                               prefix={'₹'}
                               displayType={'text'}
                               thousandsGroupStyle="lakh"
                               value= {bankTransfersTotal && bankTransfersTotal.toFixed(2)}
                               thousandSeparator={true} />
                            </div>
                          </Card.Text>
                        </Card.Body>
                        {isLoadingBankTransfers === true ? (
                            <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                              <Spinner animation="grow" role="status" size="sm" />
                            </div>
                          ) : (
                          <Button
                            href="#"
                            className="refreshBtn"
                            onClick={() => {
                              this.bankTransfers();
                              this.setState({
                                isLoadingBankTransfers: true
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faRedo} size="1x" />
                          </Button>
                        )}
                  </Card>
                </Col>
                <Col md={{ span: 4, offset: 0 }}>
                  {/* Total Balance */}
                  <Card className="cardTile">
                        <Card.Body>
                          <Card.Text style={{ fontWeight: "600" }}>
                            <div className={'name'}> Refunds ({refundsData.length})</div>
                            <div className={'value'}>
                              <NumberFormat 
                               prefix={'₹'}
                               displayType={'text'}
                               thousandsGroupStyle="lakh"
                               value= {totalRefunds && totalRefunds.toFixed(2)}
                               thousandSeparator={true} />
                            </div>
                          </Card.Text>
                        </Card.Body>
                        {isLoadingRefunds === true ? (
                            <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                              <Spinner animation="grow" role="status" size="sm" />
                            </div>
                          ) : (
                          <Button
                            href="#"
                            className="refreshBtn"
                            onClick={() => {
                              this.getTodayRefunds();
                              this.setState({
                                isLoadingRefunds: true
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faRedo} size="1x" />
                          </Button>
                        )}
                  </Card>
                </Col>
                <Col md={{ span: 4, offset: 0 }}>
                  {/* Total Balance */}
                  <Card className="cardTile">
                        <Card.Body>
                          <Card.Text style={{ fontWeight: "600" }}>
                            <div className={'name'}>Redeemption(Amazon)</div>
                            <div className={'value'}>
                              &#8377; NA
                            </div>
                          </Card.Text>
                        </Card.Body>
                        {isLoadingResellersCardBalance === true ? (
                            <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                              <Spinner animation="grow" role="status" size="sm" />
                            </div>
                          ) : (
                          <Button
                            href="#"
                            className="refreshBtn"
                            onClick={() => {
                              this.totalResellerCardBalance();
                              this.setState({
                                isLoadingResellersCardBalance: true
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faRedo} size="1x" />
                          </Button>
                        )}
                  </Card>
                </Col>
                <Col md={{ span: 4, offset: 0 }}>
                  {/* Total Balance */}
                  <Card className="cardTile">
                        <Card.Body>
                          <Card.Text style={{ fontWeight: "600" }}>
                            <div className={'name'}>Redeemption(FK)</div>
                            <div className={'value'}>
                              &#8377; NA
                            </div>
                          </Card.Text>
                        </Card.Body>
                        {isLoadingResellersCardBalance === true ? (
                            <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                              <Spinner animation="grow" role="status" size="sm" />
                            </div>
                          ) : (
                          <Button
                            href="#"
                            className="refreshBtn"
                            onClick={() => {
                              this.totalResellerCardBalance();
                              this.setState({
                                isLoadingResellersCardBalance: true
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faRedo} size="1x" />
                          </Button>
                        )}
                  </Card>
                </Col>
                <Col md={{ span: 4, offset: 0 }}>
                  {/* Total Balance */}
                  <Card className="cardTile">
                        <Card.Body>
                          <Card.Text style={{ fontWeight: "600" }}>
                            <div className={'name'}>Redeemption(Others)</div>
                            <div className={'value'}>
                              &#8377; NA
                            </div>
                          </Card.Text>
                        </Card.Body>
                        {isLoadingResellersCardBalance === true ? (
                            <div className="refreshBtn" style={{ marginTop: 10, marginRight: 10 }}>
                              <Spinner animation="grow" role="status" size="sm" />
                            </div>
                          ) : (
                          <Button
                            href="#"
                            className="refreshBtn"
                            onClick={() => {
                              this.totalResellerCardBalance();
                              this.setState({
                                isLoadingResellersCardBalance: true
                              });
                            }}
                          >
                            <FontAwesomeIcon icon={faRedo} size="1x" />
                          </Button>
                        )}
                  </Card>
                </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user
  };
};

export default connect(mapStateToProps, null)(DashboardPage);
