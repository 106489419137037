import * as actionTypes from "../actions/paymentActions";
import * as actionType from "../actions/loginActions";
import _ from "lodash";

const initialState = {
  loading: false,
  payments_approvals: [],
  error: null,
  totalCount: null,
  page: null,
  size: null,
  approval_status: false,
  variant: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    // phonenumber list
    case actionTypes.REQUEST_APPROVAL_PAYMENTS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.REQUEST_APPROVAL_PAYMENTS_SUCCESS:
      const payments_approvals = action.data.data.reduce((acc, next) => {
        next.loading = false;
        acc.push(next);
        return acc;
      }, []);

      return {
        ...state,
        loading: false,
        payments_approvals: payments_approvals,
        totalCount: action.data.totalCount,
        page: action.data.page,
        size: action.data.size,
        approval_status: false,
      };

    case actionTypes.REQUEST_APPROVAL_PAYMENTS_FAILED:
      return {
        ...state,
        loading: false,
        approval_status: true,
        variant: "danger",
      };

    case actionTypes.REQUEST_UPDATE_APPROVAL_STATUS:
      return {
        ...state,
        approval_status: false,
        payments_approvals: state.payments_approvals.map((payment) => {
          if (payment.paymentId === action.data.paymentId) {
            payment = { ...payment, loading: true };
          }
          return payment;
        }),
      };

    case actionTypes.REQUEST_UPDATE_APPROVAL_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        approval_status: true,
        variant: "success",
        payments_approvals: state.payments_approvals.filter((payment) => {
          return payment.paymentId !== action.data.paymentId;
        }),
      };

    case actionTypes.REQUEST_UPDATE_APPROVAL_STATUS_FAILED:
      return {
        ...state,
        loading: false,
        approval_status: true,
        variant: "danger",
        payments_approvals: state.payments_approvals.map((payment) => {
          if (payment.paymentId === action.data.paymentId) {
            payment = { ...payment, loading: false };
          }
          return payment;
        }),
      };

    case actionTypes.CLOSE_ALERT_UPDATE_APPROVAL:
      return {
        ...state,
        loading: false,
        approval_status: false,
        variant: "",
      };

    case actionType.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}
