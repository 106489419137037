import axios from "axios";
import { getHostUrl } from '../utils/env';

const HOST_URL = getHostUrl();

export function requestOtp(value) {
  console.log(HOST_URL, value, 'HOST_URL')
  return new Promise((res, rej) =>
    axios
      .post(`${HOST_URL}/user/register`, {
        phonenumber: value,
      })
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function verifyOtp(value, otp) {
  return new Promise((res, rej) =>
    axios
      .post(`${HOST_URL}/user/login`, {
        phonenumber: parseInt(value, 10),
        otp,
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        rej(error);
      })
  );
}

export function resendOtp(value) {
  return new Promise((res, rej) =>
    axios
      .post(`${HOST_URL}/otp/resend`, {
        number: parseInt(value, 10),
      })
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        rej(new Error(error));
      })
  );
}

export function checkBalance(phonenumber) {
  return new Promise((res, rej) =>
    axios
      .get(`${HOST_URL}/user/balance/${phonenumber}`)
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function saveFcmToken(phonenumber, token) {
  return new Promise((res, rej) =>
    axios
      .post(`${HOST_URL}/user/fcm/`, {
        phonenumber,
        token,
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function saveAnonymousUser(token) {
  return new Promise((res, rej) =>
    axios
      .post(`${HOST_URL}/user/anonymous/`, {
        token,
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function addToUserWallet(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/cards/wallet/`,
        {
          phonenumber: data.number,
          cardId: data.cardId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function fetchUserDetails(data) {
  return new Promise((res, rej) =>
    axios
      .get(`${HOST_URL}/user/${data.number}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${data.access_token}`,
        },
      })
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

export function fetchUserWalletActivity(data) {
  return new Promise((res, rej) =>
    axios
      .post(
        `${HOST_URL}/activity/phonenumber`,
        {
          phonenumber: data.number,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${data.access_token}`,
          },
        }
      )
      .then((response) => {
        res(response.data);
      })
      .catch((error) => {
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(new Error(error));
      })
  );
}

//getUserByCategory
export function getUserByCategoryAPI(data) {
  return new Promise((res, rej) =>
    axios
      .post(`
      ${HOST_URL}/user/getbycategory`, 
      {
        limit:data.limit,
        usertype : data.usertype,
        lastEvaluatedKey: data.lastEvaluatedKey,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${data.access_token}`,
        },
      })
      .then((response) => {
        res(response);
      })
      .catch((error) => {
        // console.log(error);
        console.log(error.response.data.message)
        let responseMessage = '';
      
        if ( error.response.data.message) {
          responseMessage = error.response.data.message;
        }

        console.log(responseMessage, 'err'); 
      
        if (responseMessage === 'TokenExpiredError') {
          localStorage.removeItem("state"); // Clear state from local storage
          window.location.href = "/login";
        } else {
          console.error("An unexpected error occurred:", responseMessage);
        }
        rej(error);
      })
  );
}

