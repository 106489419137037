import * as actionTypes from "../actions/resellerActivityActions";
import * as actionType from "../actions/loginActions";
import { LOCATION_CHANGE } from "connected-react-router";

const initialState = {
  loading: false,
  user_details: [],
  error: false,
  lastEvaluatedKey: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if(action.payload.location.pathname !== "/userdetails"){
        return { ...initialState };
      } else {
        return {
          ...state,
        }
      };

    case actionTypes.REQUEST_USER_DETAILS:
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
      };
    case actionTypes.REQUEST_USER_DETAILS_SUCCESS:
      const type = action.type;
      return {
        ...state,
        loading: false,
        user_details: [...state.user_details, ...action.data],
        lastEvaluatedKey: action.lastEvaluatedKey,
        errorMsg: "",
        error: false,
      };

    case actionTypes.REQUEST_USER_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actionTypes.USER_DETAILS_SWITCH_TAB: {
      return {
        ...state,
        loading: true,
        error: false,
        user_details: [],
      } 
    } 

    case actionType.LOGOUT:
      return { ...initialState };

    default:
      return state;
  }
}
