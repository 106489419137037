import React from "react";
import { connect } from "react-redux";
import { EjNavbar } from "../EjNavbar";
import {
  Navbar,
  Nav,
  Container,
  Row,
  Col,
  Card,
  Button,
  Alert,
  Spinner,
  Dropdown
} from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { addMoneyToCard, resetFormData } from "../../actions/cardActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { fetchPaymentId } from "../../actions/paymentActions";

const LoadResellerMoneySchema = Yup.object().shape({
  amount: Yup.number().required("Required"),
  transactionType: Yup.string().required("Transaction Type is required!"),
  notes: Yup.string(),
  transactionId: Yup.string().required("Transaction ID is required")
});

const TransactionTypes = {
  RAZORPAY_TRANSFER: "RAZORPAY_TRANSFER",
  BALANCE_ADJUSTMENT: "BALANCE_ADJUSTMENT",
  BANK_TRANSFER: "BANK_TRANSFER"
}

class LoadResellerMoney extends React.Component {

  componentDidMount = () => {
    this.props.resetFormData()
  }
  
  addMoney = async (value, card) => {
    let paymentId;
    try {
      paymentId = await this.props.fetchPaymentId({
        accessCode: this.props.user.accessCode
      });
    } catch (e) {
      console.log(e);
    }

    // fetchPaymentId
    const apiObj = {
      amount: value.amount,
      transactionType: value.transactionType,
      transactionId: value.transactionId,
      notes: value.notes,
      adjustmentAmount: value.transactionType === TransactionTypes.BALANCE_ADJUSTMENT ? value.amount : 0,
      ref_no: paymentId.paymentId,
      cardId: card.cardId,
      product_id: card.productId,
      phonenumber: card.phonenumber,
      accessCode: this.props.user.accessCode
    };

    await this.props.addMoneyToCard(apiObj);
  };

  render() {
    const cardId = this.props.location.substring(1);
    const card = this.props.cards.cards.find(card => card.cardId === cardId);
    const {loading, error, success } = this.props.cards;

    if (card) {
      return (
        <>
          <Container>
            <Row>
              <Col sm={{ span: 6, offset: 3 }} style={{ marginTop: 30 }}>
                {this.props.flashMsg.flashMsg !== "" &&
                this.props.flashMsg.variant !== "" ? (
                  <Alert variant={this.props.flashMsg.variant}>
                    {this.props.flashMsg.flashMsg}
                    <span style={{ float: "right" }} onClick={this.closeAlert}>
                      <FontAwesomeIcon icon={faTimes} size="1x" />
                    </span>
                  </Alert>
                ) : null}

                {(card.productId.toString() === "9142" || card.productId.toString() === "9260") ? (
                  <h2 style={{ fontWeight: "600" }}>
                    {card.productId.toString() === "9142" ? 'AMAZON' : 'AMAZON SHOPPING'}
                    </h2>
                ) : <h2 style={{ fontWeight: "600" }}>FLIPKART</h2> }

                <Card
                  style={{
                    backgroundColor: "#e1e1e1",
                    fontWeight: 'bold',
                    width: '100%',
                    fontSize: 18,
                    textAlign: 'left',
                    padding: 20,
                    alignItems: 'flex-start'
                  }}
                > 
                    <Card.Text> Phonenumber: {card.phonenumber}</Card.Text>
                    <Card.Text>CardID: {card.cardId}</Card.Text>
                    <Card.Text>Curr. Balance: &#8377;{parseFloat(card.card_balance).toFixed(2)}</Card.Text>
                </Card>
                <br/>
                  { !success && !error && <Formik
                    initialValues={{
                      amount: 0,
                      transactionType: "",
                      notes: "",
                      brandType: "",
                      transactionId: ""
                    }}
                    validationSchema={LoadResellerMoneySchema}
                    onSubmit={async (value) => await this.addMoney(value, card)}
                >
                  {({
                    touched,
                    errors,
                    isSubmitting,
                    values,
                    handleChange,
                    handleBlur
                  }) => (
                    <Form>
                      <div className="form-group">
                        <label>Transaction Type</label>
                        <Field
                          as="select"
                          name="transactionType"
                          value={values.transactionType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control ${
                            touched.transactionType && errors.transactionType
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="" label="Select a Transaction Type">
                            Select a transactionType
                          </option>
                          <option value={TransactionTypes.RAZORPAY_TRANSFER} label="Razorpay">
                            Razorpay
                          </option>
                          <option value={TransactionTypes.BANK_TRANSFER} label="Bank Transfer">
                            Bank Transfer
                          </option>
                          <option value={TransactionTypes.BALANCE_ADJUSTMENT} label="Balance Adjustment">
                            Balance Adjustment
                          </option>
                        </Field>

                        {errors.transactionType && touched.transactionType && (
                          <ErrorMessage
                            component="div"
                            name="transactionType"
                            className="invalid-feedback"
                          />
                        )}
                        <>
                        {values.transactionType === TransactionTypes.BALANCE_ADJUSTMENT ? 
                            <label>Adjustment Amount</label>
                            :
                            <label>Amount</label>
                        }
                            <Field
                              type="text"
                              name="amount"
                              placeholder="Enter Amount"
                              className={`form-control ${
                                touched.amount &&
                                errors.amount
                                  ? "is-invalid"
                                  : ""
                              }`}
                            />
                            <ErrorMessage
                              component="div"
                              name="amount"
                              className="invalid-feedback"
                            />
                          </>
                        
                        {/* <label>Brand Type</label>
                        <Field
                          as="select"
                          name="brandType"
                          value={values.brandType}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control ${
                            touched.brandType && errors.brandType
                              ? "is-invalid"
                              : ""
                          }`}
                        >
                          <option value="" label="Select a brandType">
                            Select a brandType
                          </option>
                          <option value="FLIPKART" label="Flipkart">
                            Flipkart
                          </option>
                          <option value="AMAZON" label="Amazon">
                            Amazon
                          </option>
                        </Field> */}

                        <div className="form-group">
                          <label>Transaction ID</label>
                          <Field
                            type="text"
                            name="transactionId"
                            placeholder="Enter Transaction ID"
                            className={`form-control ${
                              touched.transactionId && errors.transactionId
                                ? "is-invalid"
                                : ""
                            }`}
                          />
                          <ErrorMessage
                            component="div"
                            name="transactionId"
                            className="invalid-feedback"
                          />
                        </div>

                        <div className="form-group">
                          <label>Notes</label>
                          <Field
                            as="textarea"
                            type="text"
                            name="notes"
                            placeholder="Enter Note here"
                            className={`form-control ${
                              touched.notes && errors.notes ? "is-invalid" : ""
                            }`}
                          />
                        </div>
                        {

                        }
                        <Button
                          type="submit"
                          className="btn btn-primary btn-block"
                          disabled={isSubmitting}
                        >
                         { isSubmitting && 
                          <Spinner animation="grow" role="status" size="sm" style={{ marginRight: 10 }}>
                            <span className="sr-only">Loading...</span>
                          </Spinner>
                        }
                         &#8377; Add Money
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              }
                {
                 success && !loading &&
                  <>
                    <Alert variant={'success'}>
                      Money added successfully! New Balance: {}
                    </Alert>
                    <Button
                      type="submit"
                      className="btn btn-primary btn-block"
                      onClick={() => {this.props.resetFormData()}}
                      >+ Click to Add Another Balance</Button>
                  </>
              }
              { error && !loading &&
                  <>
                    <Alert variant={'danger'}>
                      Unable to add Money!
                    </Alert>
                    <Button
                      type="submit"
                      className="btn btn-primary btn-block"
                      onClick={() => {this.props.resetFormData()}}
                      >+ Click to Add Another Balance</Button>
                  </>
                }
                
              </Col>
            </Row>
          </Container>
        </>
      );
    } else {
      return <></>;
    }
  }
}


const mapStateToProps = state => {
  return {
    user: state.user,
    cards: state.cards,
    phoneNumbers: state.phoneNumbers,
    location: state.router.location.pathname,
    flashMsg: state.flashMsg
  };
};

export default connect(mapStateToProps, {
  addMoneyToCard,
  fetchPaymentId,
  resetFormData
})(LoadResellerMoney);
