import { getResellersCardsBalancesAPI } from "../apis/resellerCardsBalances";

export const REQUEST_RESELLERS_CARDS_BALANCES =
  "REQUEST_RESELLERS_CARDS_BALANCES";
export const REQUEST_RESELLERS_CARDS_BALANCES_SUCCESS =
  "REQUEST_RESELLERS_CARDS_BALANCES_SUCCESS";
export const REQUEST_RESELLERS_CARDS_BALANCES_FAIL =
  "REQUEST_RESELLERS_CARDS_BALANCES_FAIL";

export const getResellersCardsBalances = data => async dispatch => {
  dispatch({ type: REQUEST_RESELLERS_CARDS_BALANCES });
  return getResellersCardsBalancesAPI(data)
    .then(result => {
      const resellerCards = result.data.data;
      dispatch({
        type: REQUEST_RESELLERS_CARDS_BALANCES_SUCCESS,
        resellerCards: resellerCards,
        totalCount: result.data.totalCount,
        page: result.data.page,
        size: result.data.size
      });
    })
    .catch(() => {});
};
