import React from "react";
import { connect } from "react-redux";
import Table from "react-bootstrap/Table";
import {
  Container,
  Row,
  Col,
  Alert,
  Spinner,
  Card,
  Pagination
} from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Route, Switch, Redirect, Link } from "react-router-dom";
import { getResellersCardsBalances } from "../../actions/resellersCardsBalancesActions";
import { getCards } from "../../actions/cardActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobile } from "@fortawesome/free-solid-svg-icons";
import ResellerCardBalance from "./ResellerCardBalance";
import ResellerCardActivity from "./ResellerCardActivity";
import UserActivity from "./UserActivity";
import { indianPhoneRegexValidation } from "../../utils/regx";

const ValidPhoneSchema = Yup.object().shape({
  phone: Yup.string().matches(
    indianPhoneRegexValidation,
    "Phone number is not valid"
  )
});

class ResellerCardsBalances extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getCards = () => {
    const { loading, cards } = this.props.cards;
    return (
      <>
        <Container>
          <Row>
            <Col sm={{ span: 6, offset: 3 }} style={{ marginTop: 30 }}>
              <Formik
                initialValues={{ phone: "" }}
                validationSchema={ValidPhoneSchema}
                onSubmit={value => {
                  this.props.getCards({
                    access_token: this.props.user.accessCode,
                    number: value.phone,
                    pageNumber: 1
                  });
                }}
              >
                {({ touched, errors, isSubmitting }) => (
                  <Form>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <Field
                        type="text"
                        name="phone"
                        placeholder="Enter Valid PhoneNumber"
                        className={`form-control ${
                          touched.phone && errors.phone ? "is-invalid" : ""
                        }`}
                      />
                      <ErrorMessage
                        component="div"
                        name="phone"
                        className="invalid-feedback"
                      />
                    </div>

                    <button type="submit" className="btn btn-primary btn-block">
                      Submit
                    </button>
                  </Form>
                )}
              </Formik>

              {loading === true ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                </div>
              ) : null}

              {loading === false ? (
                <>
                  {cards.map(card => {
                    return <ResellerCardActivity card={card} />;
                  })}
                </>
              ) : null}

              {this.props.flashMsg.flashMsg !== "" &&
              this.props.flashMsg.variant !== "" ? (
                <Alert variant={this.props.flashMsg.variant}>
                  {this.props.flashMsg.flashMsg}
                </Alert>
              ) : null}
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  render() {
    return (
      <Container>
        <Switch>
          <Route
            exact
            path="/resellercardsbalances"
            component={this.getCards}
          />

          <Route
            path="/resellercardsbalances/resellercard/:phone"
            render={props => (
              <ResellerCardBalance
                data={this.props.resellerCardsBalances.balances}
                {...props}
              />
            )}
          />

          <Route
            path="/resellercardsbalances/reselleractivity/:phone/:brandType"
            render={props => (
              <UserActivity data={this.props.cards.cards} {...props} />
            )}
          />
        </Switch>
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    resellerCardsBalances: state.resellerCardsBalances,
    cards: state.cards,
    flashMsg: state.flashMsg,
    router: state.router
  };
};

export default connect(mapStateToProps, {
  getResellersCardsBalances,
  getCards
})(ResellerCardsBalances);